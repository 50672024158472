import {createSlice} from '@reduxjs/toolkit'
import {BetPrice, Config} from "./_models";
import {config_socdia} from "../../constants/local-key";


// Define a type for the slice state
type config = {
    loading: boolean,
    gameConfig: Config,
    betPrize: BetPrice[]
}


// Define the initial state using that type
const initialState: config = {
    loading: false,
    gameConfig: {
        betPrice: [],
        betTypes: []
    },
    betPrize: []
}

export const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setConfig: (state, action) => {
            state.gameConfig = action.payload;
            localStorage.setItem(config_socdia, JSON.stringify(action.payload));
            state.loading = false;
        },
        setBetPrize: (state, action) => {
            state.betPrize = action.payload;
        }
    }
})

export const {
    setLoading,
    setConfig,
    setBetPrize
}
    = configSlice.actions


export default configSlice.reducer
