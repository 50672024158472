import React, {useEffect} from "react";
import {useIntl} from "react-intl";
import {useAppSelector} from "../../../../../redux/hooks/hook";
import {getCSize} from "../../../../../../_kiloit/helpers/storage";
import {I18n} from "../../../../../../_kiloit/i18n/KiloITi18n";
import {drawStatus} from "../../../../../constants/general";
import {getColor} from "../../../../../../_kiloit/helpers/utils";
import M99Printer from "./M99Printer";
import {moneyFormatter} from "../../../../../../_kiloit/helpers/components/MoneyUtils";
import _ from "lodash";


const RecentBet = ({size, setSize}) => {

    const intl = useIntl().formatMessage;
    const {list} = useAppSelector(state => state.gameState.game.currentBet);
    const draw = useAppSelector(state => state.gameState.game.draw);
    const {balance} = useAppSelector(state => state.balance);
    const currencyCode = balance?.currencyCode

    useEffect(() => {
        setSize(getCSize());
    }, [setSize])

    return <div id="current-bet-report" className="table-responsive overflow-x-scroll bg-dark-blue">

        <div className="d-flex justify-content-center align-items-center mb-2">
            <h3 className="text-light fw-bold fs-3 text-center pt-4"><I18n t={"RECENT.BET"}/></h3>
        </div>

        {
            <>
                <table className="table mb-0 w-100 border-top border-gray-700">
                    <thead>
                    <tr className="text-white">
                        <th className="text-capitalize fw-bold">{<I18n t={"PRINT"}/>}</th>
                        <th className="text-nowrap fw-bold">{<I18n t={"CHANNEL_M99"}/>}</th>
                        <th className="text-nowrap fw-bold">{<I18n t={"FIGHT"}/>}</th>
                        <th className="text-nowrap fw-bold">{<I18n t={"SELECTED"}/>}</th>
                        <th className="text-nowrap fw-bold">{<I18n t={"STEAK"}/>}</th>
                        <th className="text-nowrap fw-bold">{<I18n t={"ODDS"}/>}</th>
                        <th className="text-nowrap fw-bold">{<I18n t={"RESULT"}/>}</th>
                        <th className="text-nowrap fw-bold">{<I18n t={"WIN_LOST"}/>}</th>
                    </tr>
                    </thead>


                    {
                        _.isEmpty(list) ? <tbody className="bg-light">
                            <tr role="row" className="b-table-empty-row">
                                <td colSpan={8} role="cell" className="">
                                    <div role="alert" aria-live="polite"><h3 className="text-center">{<I18n
                                        t={"NO_BET"}/>}</h3></div>
                                </td>
                            </tr>
                            </tbody> :
                            <tbody className="bg-light">

                            {
                                list.map((drawItem, index) => {
                                    return drawItem.tickets.map((ticket, i) => {
                                        const {tableName, drawCode, drawLabel, status, results} = drawItem;
                                        const ts = {
                                            ...ticket,
                                            channel: tableName,
                                            status: drawStatus.completed(status),
                                            drawLabel: drawLabel,
                                            drawCode: drawCode
                                        };
                                        const resultValue = results?.[0]?.result;
                                        return <tr key={i} className="text-center">
                                            <td className="text-center"><M99Printer ticket={ts}/></td>
                                            <td className="text-nowrap">{draw.tableName}</td>
                                            <td className="">#{draw.drawCode}</td>
                                            {ticket.items.map((item, j) => (
                                                <React.Fragment key={j}>
                                                    <td className={`${getColor(item.betCode)}`}>{intl({id: `${item.betCode}`})}</td>
                                                    <td className="text-primary">{moneyFormatter(item.betAmount, currencyCode)}</td>
                                                    <td>{item.rebateValue.toFixed(2)}</td>
                                                    <td className={`${getColor(resultValue)}`}>
                                                        {resultValue ? <span>{intl({id: resultValue})}</span> :
                                                            <span className="text-black">-</span>}
                                                    </td>
                                                    <td className="text-primary">
                                                        {drawStatus.completed(status) ?
                                                            moneyFormatter(item.winAmount > 0 ? item.winAmount : item.pnLAmount, currencyCode, "text-primary", false) : moneyFormatter(0, currencyCode)}
                                                    </td>
                                                </React.Fragment>
                                            ))}

                                        </tr>
                                    })
                                })
                            }
                            </tbody>}
                </table>

            </>
        }
        {/*{ticketDl && <TicketModal onClose={() => setTicket(null)} ticket={ticketDl}/>}*/}

    </div>
}
export default RecentBet;