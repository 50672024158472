import React, {FC, useRef} from "react";
import ReactToPrint from "react-to-print";
import {checkIsAndroid, sendPrintToAndroid} from "../../../../print/androidPrint";
import M99Ticket58 from "./M99Ticket58";


const M99Printer: FC<{
    ticket: any,
    icon?: any
}> = ({ticket, icon}) => {
    const printerRef = useRef<HTMLDivElement | null>();
    if (checkIsAndroid()) {
        return <button onClick={() => sendPrintToAndroid(JSON.stringify(ticket))}
                       className="bg-primary border-0 radius-5px py-1 px-2"
                       id="print-button">
            {icon ? icon : <i className="bi bi-printer fw-bold fs-6 text-white cursor-pointer"/>}
        </button>
    }
    return <>
        <ReactToPrint
            trigger={() => <button className="bg-primary border-0 radius-5px py-1 px-2" id="print-button">
                {icon ? icon : <i className="bi bi-printer fw-bold fs-6 text-white cursor-pointer"/>}
            </button>}
            content={() => printerRef.current!}
        />
        <div className="d-none">
            <div ref={el => (printerRef.current = el)}>
                <M99Ticket58 ticket={ticket}/>
            </div>
        </div>
    </>
}

export default M99Printer;