/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {toAbsoluteUrl} from "../../../../_kiloit/helpers";
import {login} from "../core/_requests";
import {useNavigate} from "react-router-dom";
import {showToastError} from "../../../../_kiloit/helpers/Toast";
import {toast, ToastContainer} from "react-toastify";
import {useAuth} from "../core/action";

const loginSchema = Yup.object().shape({
    username: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('username is required'),
    password: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
})

const initialValues = {
    username: '',
    password: '',
}

export function Login() {
    const [loading, setLoading] = useState(false)
    const {saveAuth, setCurrentUser} = useAuth()
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, {setSubmitting}) => {
            setLoading(true)
            try {
                const auth = await login(values.username, values.password)
                saveAuth(auth)
                setCurrentUser(auth.user);
                navigate('/')
            } catch (error) {
                console.error(error)
                // @ts-ignore
                showToastError(error.response.data.message, {position: toast.POSITION.TOP_CENTER});
                saveAuth(undefined)
                setSubmitting(false)
                setLoading(false)
            }
        },
    })

    return (
        <div className="m99-login px-5">
            <div className="d-flex justify-content-center w-100 mb-5">
                <img className="w-50" src={toAbsoluteUrl("/media/cockfighting/logo.png")}
                     alt="__"/>

            </div>
            <br/>
            <br/>
            <div className="card bg-transparent login-card">
                <ToastContainer/>
                <div className="card-body">
                    <form
                        className='form w-100'
                        onSubmit={formik.handleSubmit}
                        noValidate
                        id='kt_login_signin_form'
                    >
                        {/* begin::Heading */}


                        <div className='text-center mb-2 w-100 text-center'>
                            <h3 className='fw-bolder py-3 text-uppercase text-center fw-bold'>Login</h3>
                        </div>
                        <br/>
                        {/* begin::Form group */}
                        <div className='fv-row mb-8'>
                            <input
                                {...formik.getFieldProps('username')}
                                className={clsx(
                                    'form-control bg-transparent login-border',
                                    {'is-invalid': formik.touched.username && formik.errors.username},
                                    {
                                        'is-valid': formik.touched.username && !formik.errors.username,
                                    }
                                )}
                                type='username'
                                name='username'
                                placeholder="Username"
                                autoComplete='off'
                            />
                            {formik.touched.username && formik.errors.username && (
                                <div className='fv-plugins-message-container'>
                                    <span role='alert' className="text-danger">{formik.errors.username}</span>
                                </div>
                            )}
                        </div>
                        {/* end::Form group */}

                        {/* begin::Form group */}
                        <div className='fv-row mb-3'>
                            <input
                                type='password'
                                placeholder="Password"
                                autoComplete='off'
                                {...formik.getFieldProps('password')}
                                className={clsx(
                                    'form-control bg-transparent login-border',
                                    {
                                        'is-invalid': formik.touched.password && formik.errors.password,
                                    },
                                    {
                                        'is-valid': formik.touched.password && !formik.errors.password,
                                    }
                                )}
                            />
                            {formik.touched.password && formik.errors.password && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.password}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        {/* end::Form group */}

                        {/* begin::Action */}
                        <div className='d-grid mb-10 mt-10 login-border'>
                            <button
                                type='submit'
                                id='kt_sign_in_submit'
                                className='btn  login-border'
                                disabled={formik.isSubmitting || !formik.isValid}
                            >
                                {!loading && <span className='indicator-label text-uppercase fw-bold'>Login</span>}
                                {loading && (
                                    <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'/>
            </span>
                                )}
                            </button>
                        </div>
                        {/* end::Action */}
                    </form>
                </div>
            </div>
            <div className="row mt-3 text-center">
                <div className="col"><a href="#" target="_self" className="text-brand fw-bold">
                    Download M99 on Android
                </a></div>
            </div>
        </div>
    )
}
