import {moneyFormatter} from "../../../../../../_kiloit/helpers/components/MoneyUtils";
import {useAppSelector} from "../../../../../redux/hooks/hook";
import {getDateDDMMYYYY} from "../../../../../../_kiloit/helpers/dateUtils";
import RefreshBtn from "../../../../../../_kiloit/partials/widgets/RefreshButton";
import {useBalance} from "../../../../balance/action";
import RecentBet from "../../../../report/sale/components/RecentBet";
import {useIntl} from "react-intl";
import {FC, useState} from "react";
import {ID} from "../../../../../../_kiloit/helpers";

const CurrentTicketWrapper: FC<{ tableID: ID }> = ({tableID}) => {

    const intl = useIntl().formatMessage;
    const {balance} = useAppSelector(state => state.balance);
    const game = useAppSelector(state => state.gameState.game)
    const draw = game.draw;
    const {fetchBalance, getOutBl} = useBalance();
    const [size, setSize] = useState(3)


    return <div className="overflow-x-scroll overflow-y-hidden w-100">

        <table className="table border">
            <tbody>
            <tr>
                <td className="border-bottom">
                    <h4 className="">{getDateDDMMYYYY(null)} #{draw?.currentDraw?.drawCode}</h4>
                </td>
                <td className="border-bottom text-end">
                    <RefreshBtn onRefresh={() => fetchBalance()}/>
                </td>
            </tr>
            <tr>
                <td className="border-bottom">
                    <h4 className="">{intl({id: "BALANCE"})}</h4>
                </td>
                <td className="border-bottom text-end">
                    <h4 className="text-blue">{moneyFormatter(balance.balance, balance.currencyCode)}</h4>
                </td>
            </tr>
            <tr>
                <td className="border-bottom">
                    <h4 className="">{intl({id: "OUTSTANDING"})}</h4>
                </td>
                <td className="border-bottom text-end">
                    <h4 className="text-blue">{moneyFormatter(getOutBl(tableID).balance, balance.currencyCode)}</h4>
                </td>
            </tr>
            </tbody>
        </table>
        {/*<div className="px-3 border-bottom">*/}
        {/*    <h2>{intl({id: "RECENT.BET"})}</h2>*/}
        {/*</div>*/}
        <RecentBet size={size} setSize={setSize}/>
    </div>

}

export default CurrentTicketWrapper;