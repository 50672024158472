import React, {FC, useState} from 'react';
import * as Yup from "yup";
import {useFormik} from "formik";
import {resetPassword} from "../core/_requests";
import {showToastBetSuccess, showToastError} from "../../../../_kiloit/helpers/Toast";
import clsx from "clsx";
import {toast, ToastContainer} from "react-toastify";
import {useIntl} from "react-intl";


const changePassSc = Yup.object().shape({
    currentPassword: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Current Password is required'),
    newPassword: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('New Password is required'),
    confirmNewPassword: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Confirm Password is required'),
});
const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: ""
}


const ChangePassword: FC = () => {

    const [loading, setLoading] = useState(false);
    const intel = useIntl().formatMessage;


    const formik = useFormik({
        initialValues,
        validationSchema: changePassSc,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true);
            setSubmitting(true);
            resetPassword(values).then(() => {
                setSubmitting(false);
                setLoading(false);
                showToastBetSuccess("Password has been change.", {position: toast.POSITION.TOP_CENTER});
            }).catch((error) => {
                setSubmitting(false);
                setLoading(false);
                showToastError(error.response.data.message, {position: toast.POSITION.TOP_CENTER});
            })
        },
    })

    return (
        <div className="h-100 bg-black-l p-5">
            <div className="w-50 m-auto">
                <div className="my-5 ">
                    <h2>{intel({id: "PASSWORD"})}</h2>
                </div>
                <form
                    className='form w-100'
                    onSubmit={formik.handleSubmit}
                    noValidate
                    id='kt_login_change_pass'
                >
                    <div className='fv-row mb-8'>
                        <label
                            className='form-label fs-6 fw-bolder text-white'>{intel({id: "CURRENT.PASSWORD"})}</label>
                        <input
                            placeholder={`${intel({id: "CURRENT.PASSWORD"})}`}
                            {...formik.getFieldProps('currentPassword')}
                            className={clsx(
                                'form-control bg-transparent text-white',
                                {'is-invalid': formik.touched.currentPassword && formik.errors.currentPassword},
                                {
                                    'is-valid': formik.touched.currentPassword && !formik.errors.currentPassword,
                                }
                            )}
                            type='text'
                            name='currentPassword'
                            autoComplete='off'
                        />
                        {formik.touched.currentPassword && formik.errors.currentPassword && (
                            <div className='fv-plugins-message-container'>
                                <span role='alert'>{formik.errors.currentPassword}</span>
                            </div>
                        )}
                    </div>

                    <div className='fv-row mb-8'>
                        <label className='form-label fs-6 fw-bolder text-white'>{intel({id: "NEW.PASSWORD"})}</label>
                        <input
                            placeholder={`${intel({id: "NEW.PASSWORD"})}`}
                            {...formik.getFieldProps('newPassword')}
                            className={clsx(
                                'form-control bg-transparent text-white',
                                {'is-invalid': formik.touched.newPassword && formik.errors.newPassword},
                                {
                                    'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
                                }
                            )}
                            type='text'
                            name='newPassword'
                            autoComplete='off'
                        />
                        {formik.touched.newPassword && formik.errors.newPassword && (
                            <div className='fv-plugins-message-container'>
                                <span role='alert'>{formik.errors.newPassword}</span>
                            </div>
                        )}
                    </div>

                    <div className='fv-row mb-8'>
                        <label
                            className='form-label fs-6 fw-bolder text-white'>{intel({id: "CONFIRM.NEW.PASSWORD"})}</label>
                        <input
                            placeholder={`${intel({id: "CONFIRM.NEW.PASSWORD"})}`}
                            {...formik.getFieldProps('confirmNewPassword')}
                            className={clsx(
                                'form-control bg-transparent text-white',
                                {'is-invalid': formik.touched.confirmNewPassword && formik.errors.confirmNewPassword},
                                {
                                    'is-valid': formik.touched.confirmNewPassword && !formik.errors.confirmNewPassword,
                                }
                            )}
                            type='text'
                            name='confirmNewPassword'
                            autoComplete='off'
                        />
                        {formik.touched.confirmNewPassword && formik.errors.confirmNewPassword && (
                            <div className='fv-plugins-message-container'>
                                <span role='alert'>{formik.errors.confirmNewPassword}</span>
                            </div>
                        )}
                    </div>


                    {/* begin::Action */}
                    <div className='d-grid mb-10 mt-10'>
                        <button
                            type='submit'
                            id='kt_sign_in_submit'
                            className='btn btn-primary'
                            disabled={formik.isSubmitting || !formik.isValid}
                        >
                            {!loading && <span className='indicator-label'>{intel({id: "CONTINUE"})}</span>}
                            {loading && (
                                <span className='indicator-progress' style={{display: 'block'}}>
              {intel({id: "PLEASE.WAIT"})}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'/>
            </span>
                            )}
                        </button>
                    </div>
                    {/* end::Action */}
                </form>
            </div>
            <ToastContainer/>
        </div>
    );
};

export default ChangePassword;