import {createSlice} from '@reduxjs/toolkit'
import {Balance, initBalance} from "./_models";


// Define a type for the slice state
type config = {
    loading: boolean,
    balance: Balance
}


// Define the initial state using that type
const initialState: config = {
    loading: false,
    balance: initBalance
}

export const balanceSlice = createSlice({
    name: 'balance',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setBalance: (state, action) => {
            state.balance = action.payload;
            state.loading = false;
        },
    }
})

export const {
    setLoading,
    setBalance,
} = balanceSlice.actions


export default balanceSlice.reducer
