import React from "react";
import {useIntl} from "react-intl";
import {useAppSelector} from "../../../redux/hooks/hook";
import {moneyFormatter} from "../../../../_kiloit/helpers/components/MoneyUtils";
import {getFullDateTimePrint} from "../../../../_kiloit/helpers/dateUtils";
import {useAuth} from "../../auth/core/action";

const PrintItems = (props) => {
    const {object, status, isChinese, ticket} = props;
    const {betCode, betAmount, rebateValue, winAmount} = object;
    const tableName = useAppSelector(state => state.gameState.game.draw?.tableName);
    const intl = useIntl().formatMessage;

    const {currentUser} = useAuth();

    const RenderTR = ({label, item, subItem = ""}) => {
        return <div style={{lineHeight: "25px"}} key={label} className="d-flex m-0 align-items-center">
            <span style={{width: "40%"}}
                  className={isChinese ? "font-weight-none text-right f-bayon" : "font-weight-bolder text-right f-bayon f-22 p-0 m-0"}>{label}:</span>
            {
                subItem ?
                    <span className="text-center">
                        {moneyFormatter(item, null, "text-black f-bayon f-22 font-weight-bolder")} <span
                        className="f-bayon f-22 font-weight-bolder">X {subItem}</span>
                    </span> :
                    <span className="f-bayon font-weight-bolder f-22 align-items-center text-center">
                        {item}
                    </span>
            }
        </div>
    };

    const RenderDate = (props: any) => {
        const {label, item} = props;
        return <div style={{lineHeight: "25px"}} className="d-flex align-items-center">
            <span style={{width: "40%"}}
                  className={isChinese ? "font-weight-none text-right f-bayon" : "font-weight-bolder text-right f-bayon f-22 p-0 m-0"}>{label}:</span>

            <span className="f-bayon font-weight-bolder f-22 align-items-center text-center">
                        {getFullDateTimePrint(item)}
                    </span>
        </div>
    }

    const RenderChannel = (props: any) => {
        const {label, item} = props;
        return <div style={{lineHeight: "25px"}} className="d-flex align-items-center">
            <span style={{width: "40%"}}
                  className={isChinese ? "font-weight-none text-right f-bayon" : "font-weight-bolder text-right f-bayon f-22 p-0 m-0"}>{label}:</span>
            {/*<span className="f-bayon f-22">&nbsp;:&nbsp;</span>*/}

            <span className="f-bayon font-weight-bolder f-22 align-items-center text-center">
                        {item}
                    </span>
        </div>
    }

    const RenderDrawCode = (props: any) => {
        const {label, item} = props;
        return <div style={{lineHeight: "25px"}} className="d-flex align-items-center">
            <span style={{width: "40%"}}
                  className={isChinese ? "font-weight-none text-right f-bayon" : "font-weight-bolder text-right f-bayon f-22 p-0 m-0"}>{label}:</span>
            {/*<span className="f-bayon f-22">&nbsp;:&nbsp;</span>*/}

            <span className="f-bayon font-weight-bolder f-22 align-items-center text-center">
                        #{item}
                    </span>
        </div>
    }


    return <tr>
        <td className="p-0 text-dark font-weight-bolder">
            <RenderChannel label={intl({id: "CHANNEL"})} item={tableName}/>
            <RenderDrawCode label="Round" item={ticket.drawCode}/>
            <RenderDate label="Date" item={ticket.createdAt}/>
            <RenderTR label="ប្រភេទ" item={intl({id: `${betCode}`})}/>
            <RenderTR label="Account" item={currentUser?.username}/>
            <RenderTR label="Bet x Odds" item={betAmount} subItem={rebateValue.toFixed(2)}/>
            <RenderTR label="Payout"
                      item={status ? moneyFormatter(winAmount, null, "text-primary f-bayon f-22 font-weight-bolder") : "????"}/>
        </td>

    </tr>
};

export default PrintItems;
