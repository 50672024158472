import {FC} from "react";
import {Table} from "react-bootstrap";
import {useAppSelector} from "../../../../../redux/hooks/hook";
import _ from "lodash";
import {stP99Label} from "../../../../../constants/game";

const HistoryTable: FC = () => {
    const {underOver, betCodes} = useAppSelector(state => state.gameState.game.statistic);

    return <div className="bg-black-dl ff-montserrat">
        <div id="history-table" className="w-100 max-w-100 overflow-x-scroll border bg-white">
            <Table className="border-bottom his-table p99-st-bg-tb">
                <tbody>
                {underOver?.map((e, i) => {
                    return <tr key={i}>
                        {e.map((p, j) => {
                            const [code, draw] = p.split("_");
                            return <td key={j} className="text-danger pd-1px st-border">
                                <div className="d-flex justify-content-center align-items-center">
                                    <div
                                        className={`d-flex justify-content-center align-items-center ${code ? "st-shadow" : ""} tb-aspec st-${_.toLower(code)}`}>
                                        <div
                                            className="d-flex justify-content-center align-items-center p-0 fs-6 fs-lg-4  text-white fw-bold">
                                            {draw}
                                        </div>
                                    </div>
                                </div>
                            </td>
                        })}
                    </tr>
                })}
                </tbody>
            </Table>
        </div>
        <div className="d-flex justify-content-evenly py-8">
            {betCodes.map((item, index) => {
                return <div key={index} className="d-flex flex-column align-items-center">
                    <div
                        className={`w-40px h-40px border border-2 border-white rounded-circle d-flex justify-content-center align-items-center st-${item.betCode.toLowerCase()}`}>
                        <span className="fs-4 fw-bold text-white">
                            {item.value}
                        </span>
                    </div>
                    <div>
                        <span className="fs-6 fw-bold text-white">
                            {stP99Label[item.betCode]}
                        </span>
                    </div>
                </div>
            })}
        </div>
    </div>
}

export {HistoryTable}