import clsx from "clsx";
import React from "react";
import {useAppSelector} from "../../../../../redux/hooks/hook";
import {getStatus} from "../../../../../../_kiloit/helpers/utils";

const M99ChannelSection = ({items, activeTable, onChangeTable}) => {
    const game = useAppSelector((state) => state.gameState.game);
    const drawCode = game.draw;
    const status = drawCode?.currentDraw?.status; // Added null check
    const gameStatus = getStatus(status);

    return (
        <ul id="m99-live" className="d-flex justify-content-start bg-light">
            {items.map((tab: any, index: number) => {
                const isActive = tab.tableId === activeTable;
                const getActiveBg = (isActive: any, gameStatus: any) => {
                    if (isActive && gameStatus === "OPEN") {
                        return "bg-primary text-white";
                    }
                    if (isActive && gameStatus === "CLOSE") {
                        return "text-white bg-danger";
                    }
                    return
                }

                return (
                    <li
                        key={index}
                        onClick={() => onChangeTable(tab.tableId)}
                        className={`my-0 border-0 text-center cursor-pointer fs-4 border w-25 py-2 ${getActiveBg(isActive, gameStatus)}`}
                    >
                        <span>{tab.tableName}</span>
                        <i className={clsx("fa-solid fa-tv m-2", {"text-white": isActive})}></i>
                        <span className={gameStatus === "OPEN" ? "open-channel" : "close-channel"}></span>
                    </li>
                );
            })}
        </ul>
    );
};

export default M99ChannelSection;
