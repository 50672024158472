const env = {
    apiUrl: process.env.REACT_APP_API_URL,
    socketUrl: process.env.REACT_APP_SOCKET_URL,
    version: process.env.REACT_APP_VERSION,
    componentIframe: process.env.REACT_APP_IFRAME,
    basicToken: process.env.REACT_APP_BASIC_TOKEN,
    merchantId: process.env.REACT_APP_X_MERCHANT_ID,
    platformType: process.env.REACT_APP_PLATFORM_TYPE,
}

export {env}