import {getLocalSt, stKeys} from "./storage";

const toK = (value: any) => {
    if (value >= 1000) {
        let val = value.toString();
        return val.substring(0, val.length - 3).concat("k")
    }
    return value;
}

const addClassById = ({id, classAdd = "", classRemove = "", removedAfter = 0}) => {
    const doc = document.getElementById(id);
    if (doc) {
        if (classRemove) doc.classList.remove(classRemove);
        if (classAdd) doc.classList.add(classAdd);
        if (removedAfter > 0) setTimeout(() => {
            if (classAdd) doc.classList.remove(classAdd);
        }, removedAfter)
    }
}

const removedAllClass = ({className}) => {
    let docs: HTMLCollectionOf<Element> = document.getElementsByClassName(className);
    if (docs.length > 0) {
        for (let i = 0; i < docs.length; i++) {
            docs[i].classList.remove(className)
        }
    }
}

const changeImgSrc = ({id, src = ""}) => {
    const doc = document.getElementById(id) as HTMLImageElement;
    if (doc) doc.src = src;
}

const scrollTop = (id: string) => {
    let doc = document.getElementById(id);
    if (doc) {
        doc.scrollTop = 0;
    }
}

const scrollLeft = (id: string) => {
    let doc = document.getElementById(id);
    setTimeout(() => {
        if (doc) doc.scrollLeft = doc.scrollWidth;
    }, 500)
}

const clickId = (id: string) => {
    let doc = document.getElementById(id);
    if (doc) doc.click();
}

const isIframe = () => {
    const isIf = getLocalSt(stKeys.isIframe);
    return isIf === "1";
}

const getStatusLabel = (status: any) => {
    switch (status) {
        case 0:
            return "OPEN";
        default:
            return "LIVE"
    }
};

const getStatus = (status: any) => {
    switch (status) {
        case 0:
            return "OPEN";
        default:
            return "CLOSE"
    }
};

const getColor = (value: any) => {
    switch (value) {
        case 'RED':
            return 'text-danger';
        case 'BLUE':
            return 'text-primary';
        case 'DRAW':
            return 'text-success';
        case 'CANCEL':
            return 'text-cancel';
        default:
            return 'text-secondary'; // Default color or error handling if needed
    }
};

const getTranslateId = (translate: any) => {
    switch (translate) {
        case 'BLUE':
            return 'BLUE';
        case 'RED':
            return 'RED';
        case 'DRAW':
            return 'DRAW';
        case 'CANCEL':
            return 'CANCEL';
        default:
            return "NONE"
    }
}

export {
    toK,
    addClassById,
    changeImgSrc,
    removedAllClass,
    clickId,
    scrollTop,
    isIframe,
    getStatusLabel,
    getStatus,
    getColor,
    getTranslateId,
    scrollLeft
}

