import {AuthModel, UserModel} from "./_models";
import * as authHelper from "./AuthHelpers";
import {setAuth, setUser} from "./reducer";
import {useDispatch} from "react-redux";
import {gameSetting} from "../../../constants/config";
import {useAppSelector} from "../../../redux/hooks/hook";
import {getUserByToken, requestLogout} from "./_requests";
import {showToastError} from "../../../../_kiloit/helpers/Toast";
import {toast} from "react-toastify";


const useAuth = () => {
    const dispatch = useDispatch();
    const auth = useAppSelector(state => state.auth);
    const currentUser = auth.user;

    const setCurrentUser = (value: UserModel | undefined) => dispatch(setUser(value));

    const saveAuth = (auth: AuthModel | undefined) => {
        dispatch(setAuth(auth));
        if (auth) {
            authHelper.setAuth(auth)
        } else {
            authHelper.removeAuth()
        }
    }

    const getCurrentUser = (token: string) => {
        return getUserByToken(token).then((response) => {
            setCurrentUser(response.data);
            saveAuth({token: token});
        }).catch((error) => {
            console.log(error);
        })
    }


    const logout = () => {
        requestLogout().then(() => {
            saveAuth(undefined)
            setCurrentUser(undefined)
            localStorage.removeItem(gameSetting.configKey)
        }).catch((error) => {
            const errorMsg = error.response.data.message ?? "Cannot log out"
            showToastError(errorMsg, {
                position: toast.POSITION.TOP_CENTER
            })
            return error;
        })
    }


    return {logout, saveAuth, setCurrentUser, auth, currentUser, getCurrentUser}
}

export {useAuth}