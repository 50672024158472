import {useEffect, useState} from 'react'
import {useNavigate, useParams} from "react-router-dom";
import {Button} from "react-bootstrap";
import {getLocalSt, setLocalSt, stKeys} from "../../../../_kiloit/helpers/storage";
import {verifiedToken} from "../core/_requests";
import {useAuth} from "../core/action";

export function VerifyToken() {
    const {currentUser, setCurrentUser, getCurrentUser} = useAuth();
    const token = useParams().token;
    const navigate = useNavigate();
    const [verified, setVerify] = useState(true);
    useEffect(() => {
        localStorage.clear();
        setCurrentUser(undefined);
        setLocalSt(stKeys.isIframe, "1");
        if (token) {
            verifiedToken(token).then((auth) => {
                setLocalSt(stKeys.redirectUrl, auth.urlBack ?? "");
                if (auth.token) {
                    getCurrentUser(auth.token).then((user) => {
                    }).catch(_ => {
                        setVerify(false);
                    });
                    setVerify(true);

                } else {
                    setVerify(false);
                }
            }).catch((_) => {
                setVerify(false);
            })
        }
        // eslint-disable-next-line
    }, [token]);

    useEffect(() => {
        currentUser && navigate('/lobby');
    }, [currentUser, navigate]);

    if (!verified) return <div className="min-h-1px mx-auto my-auto">
        <div className="fs-3 text-center">Something went wrong!</div>
        <div className="mt-6 text-center">
            <a href={getLocalSt(stKeys.redirectUrl)} className="btn btn-secondary me-5">
                Back
            </a>
            <Button onClick={() => window.location.reload()} className="btn btn-primary">
                Retry
            </Button>
        </div>
    </div>
    return <div className="d-flex justify-content-center align-items-center">
        Loading...
    </div>
}
