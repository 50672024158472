import {useEffect} from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {toAbsoluteUrl} from "../../../helpers";
import Image from "react-bootstrap/Image";
import {Dropdown} from "react-bootstrap";
import {DateTimer} from "../../../partials/widgets/DateTimer";
import {useIntl} from "react-intl";
import {useAppSelector} from "../../../../app/redux/hooks/hook";
import {moneyFormatter} from "../../../helpers/components/MoneyUtils";
import {useBalance} from "../../../../app/modules/balance/action";
import {useGame} from "../../../../app/modules/game/core/action";
import {isIframe} from "../../../helpers/utils";
import {getLocalSt, stKeys} from "../../../helpers/storage";
import {LanguagesCustom} from "../../../partials/layout/header-menus/LanguagesCustom";
import {useAuth} from "../../../../app/modules/auth/core/action";
import {sendPrintToAndroid} from "../../../../app/modules/print/androidPrint";
import {env} from "../../../../app/constants/env";

const Header = () => {
    const {currentUser, logout} = useAuth();
    const navigate = useNavigate();

    const intl = useIntl().formatMessage;
    const {balance} = useAppSelector(state => state.balance);
    const draw = useAppSelector(state => state.gameState.game.draw);
    const {fetchBalance} = useBalance();
    const param = useParams();

    const {fetchCurrentBet} = useGame();
    const tableId = draw.tableId ?? parseInt(param?.tableId ?? "")

    useEffect(() => {
        fetchBalance();
        fetchCurrentBet({tableId: tableId});
        // eslint-disable-next-line
    }, [])

    const handleSetting = () => {
        sendPrintToAndroid("Setting");
    };
    const location = useLocation()
    const isHome = location.pathname.length === 1;
    const backBtn = !isHome && <Link to="/" className="p-1 btn-back w-35px h-35px"/>

    return <nav className="navbar navbar-expand-lg bg-dark p-0">
        {/*Small Screen*/}
        <div className="container-fluid d-flex d-lg-none justify-content-between p-0">
            <div className="d-flex justify-content-between align-items-center" id="navbarSupportedContent">
                {(isIframe() && isHome) && <a href={getLocalSt(stKeys.redirectUrl)} className="btn-back">{""}</a>}
                {backBtn}
                <Link className="navbar-brand" to="/">
                    <Image
                        className="w-40px ps-1"
                        src={toAbsoluteUrl("/media/cockfighting/logo.png")}
                        alt="__"
                        loading="lazy"
                    />
                </Link>
                <div className="flex-column justify-content-center align-items-center">
                    <div className="text-light fw-bolder mt-2 fs-7">Cock Fighting</div>
                    <div className="fs-6 text-secondary opacity-50">{env.version}</div>
                </div>
            </div>


            <div className="d-flex justify-content-center align-items-center">
                <div className="text-end">
                    <div className="text-light fs-8 fw-bolder">{currentUser?.username?.toUpperCase()}</div>

                    <div>
                        <span className="text-success fw-bold">
                            {moneyFormatter(balance.balance, balance.currencyCode)}
                        </span>
                    </div>
                    {/*<div>*/}
                    {/*    <span*/}
                    {/*        className="text-light fw-bold">{moneyFormatter(getOutBl(tableId).balance, balance.currencyCode)}</span>*/}
                    {/*</div>*/}
                </div>
                <Dropdown className="bg-transparent mx-2">
                    <Dropdown.Toggle className="bg-transparent btn-secondary p-3">
                        <i className="bi bi-list fs-1 text-light"/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="bg-light w-250px">
                        {/*<Dropdown.Item onClick={() => navigate(`/lobby/${tableId}/statement`)}*/}
                        {/*               className="border-bottom text-dark fw-bold fs-6 py-4">*/}
                        {/*    <i className="bi bi-ticket text-warning fs-2 me-4"/>*/}
                        {/*    <span>Ticket</span>*/}
                        {/*</Dropdown.Item>*/}
                        <Dropdown.Item onClick={() => navigate(`/app-report/${tableId}`)}
                                       className="border-bottom text-dark fw-bold fs-6 py-4">
                            <i className="fa-solid fa-file-text text-warning fs-2 me-4"/>
                            <span>Report</span>
                        </Dropdown.Item>
                        {!isIframe() &&
                            <>
                                <Dropdown.Item onClick={() => navigate("/change-pw")}
                                               className="border-bottom text-dark fw-bold fs-4 py-4">
                                    <i className="fa-solid fa-lock text-primary fs-2 me-4"/>
                                    <span>{intl({id: "PASSWORD"})}</span>
                                </Dropdown.Item>
                                <LanguagesCustom/>
                                <Dropdown.Item className="fw-bold py-4" onClick={handleSetting}>
                                    <i className="bi bi-gear text-black fs-4 fw-bold me-4"></i>
                                    <span className="fs-4 fw-bold">{intl({id: "SETTING"})}</span>
                                </Dropdown.Item>
                                <hr className="m-0 p-0"/>
                                <Dropdown.Item
                                    onClick={() => logout()}
                                    className="text-dark fw-bold fs-4 py-4">
                                    <i className="fa-solid fa-right-from-bracket text-danger fs-2 me-4"/>
                                    <span>{intl({id: "LOGOUT"})}</span>
                                </Dropdown.Item>
                            </>
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>

        {/*Large Screen*/}
        <div className="container-fluid d-none d-lg-flex">
            <div className="d-flex align-items-center" id="navbarSupportedContent">
                {(isIframe() && isHome) && <a href={getLocalSt(stKeys.redirectUrl)} className="btn-back">{""}</a>}
                {backBtn}
                <Link className="navbar-brand" to="/">
                    <Image
                        className="w-40px"
                        src={toAbsoluteUrl("/media/cockfighting/logo.png")}
                        alt="MDB Logo"
                        loading="lazy"
                    />
                </Link>
                <div className="flex-column justify-content-center align-items-center">
                    <div className="text-light fw-bolder mt-2 fs-5">Cock Fighting</div>
                    <div className="fs-6 text-secondary opacity-50">{env.version}</div>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
                <div className="text-light fs-4 fw-bolder">{currentUser?.username?.toUpperCase()}</div>
            </div>

            <div className="d-flex justify-content-center align-items-center ">
                <div className="text-light px-2 fs-6 fw-bolder">
                    <DateTimer/>
                </div>

                <Dropdown className="bg-transparent m-1">
                    <Dropdown.Toggle className="bg-transparent">
                        <i className="bi bi-list fs-1 text-light"/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="bg-light w-250px">
                        <Dropdown.Item onClick={() => navigate(`/report/${tableId}`)}
                                       className="border-bottom text-dark fw-bold fs-4 py-4">
                            <i className="fa-solid fa-chart-column text-dark fs-2 me-4"/>
                            <span>{intl({id: "REPORT"})}</span>
                        </Dropdown.Item>

                        {!isIframe() && <>
                            <Dropdown.Item onClick={() => navigate("/change-pw")}
                                           className="border-bottom text-dark fw-bold fs-4 py-4">
                                <i className="fa-solid fa-lock text-primary fs-2 me-4"/>
                                <span>{intl({id: "PASSWORD"})}</span>
                            </Dropdown.Item>
                            <LanguagesCustom/>
                            <hr className="m-0 p-0"/>
                            <Dropdown.Item
                                onClick={() => logout()}
                                className="text-dark fw-bold fs-4 py-2">
                                <i className="fa-solid fa-right-from-bracket text-danger fs-2 me-4"/>
                                <span>{intl({id: "LOGOUT"})}</span>
                            </Dropdown.Item>
                        </>}

                    </Dropdown.Menu>
                </Dropdown>

            </div>
        </div>
    </nav>
}

export {Header};