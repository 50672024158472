import React, {useState} from "react";
import clsx from "clsx";

const BtnRefresh = ({onRefresh, className = ""}) => {
    const [loading, setLoading] = useState(false);

    const onStartRefresh = async () => {
        setLoading(true);
        await onRefresh();
        setTimeout(() => {
            setLoading(false)
        }, 200)
    }
    return <div>
        {loading ?
            <span className='spinner-border cursor-pointer display-6 fw-bolder text-white mx-1'/>
            : <i onClick={onStartRefresh}
                 className={clsx("las la-sync cursor-pointer display-6 fw-bolder text-white mx-1  cursor-pointer " + className)}/>
        }

    </div>
}

export default BtnRefresh;