import React, {createContext, FC, useContext, useState} from 'react'
import {WithChildren} from '../helpers'
import {FormattedMessage} from "react-intl";

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'

type Props = {
    selectedLang: 'km' | 'en' | 'zh' | 'vi' | 'th' | 'lo' | 'id',
    setLang: (lang: any) => void;
}

type PropsTranslate = {
    t: any,
    val?: any
}

const initialState: Props = {
    selectedLang: 'en',
    setLang: () => {
    }
}

function getConfig(): Props {
    const ls = localStorage.getItem(I18N_CONFIG_KEY)
    if (ls) {
        try {
            return JSON.parse(ls) as Props
        } catch (er) {
            console.error(er)
        }
    }
    return initialState
}

// Side effect
export function setLanguage(lang: string) {
    localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: lang}))
}

const I18nContext = createContext<Props>(initialState)

const useLang = () => {
    return useContext(I18nContext)
}

const KiloITI18nProvider: FC<WithChildren> = ({children}) => {
    const lang = getConfig();
    const [selectedLang, setSelectedLang] = useState(lang.selectedLang);
    const setLang = (lang: any) => {
        setSelectedLang(lang)
        setLanguage(lang)
    }
    return <I18nContext.Provider value={{selectedLang, setLang}}>{children}</I18nContext.Provider>
}

const I18n: FC<PropsTranslate> = ({t, val}) => {
    return <FormattedMessage id={t} values={val}/>
}

export {KiloITI18nProvider, useLang, I18n}
