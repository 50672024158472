import {config_socdia} from "./local-key";

export type gameSettingModel = {
    name: string,
    configKey: string,
    productCode: string,
}

const gameSetting: gameSettingModel = {
    name: "COCKFIGHT",
    configKey: config_socdia,
    productCode: "COCKFIGHT"
}

const dishAssets = new Map([
    ["B3", 4],
    ["B4", 1],
    ["W2B2", 6],
    ["W3", 4],
    ["W4", 1],
]);

const drawStatusSocket = {
    release: "release",
    open: "open",
    cashOut: "cash-out",
}

const drawMessage = new Map([
    ["release", "Chờ mở thưởng!"],
    ["open", "Đã bắt đầu, vui lòng đặt cược!"],
]);

const cDrawStatus = new Map([
    [0, "open"],
    [1, "release"],
]);

const gameType = {
    manual: "manual",
    auto: "auto"
}

const betEOS = ["ODD", "EVEN", "TAI", "XIU"];

export {
    gameSetting,
    dishAssets,
    drawStatusSocket,
    drawMessage,
    cDrawStatus,
    gameType,
    betEOS
}