import {toAbsoluteUrl} from "../helpers";

export const languages = [
    {
        lang: 'km',
        name: 'Khmer',
        flag: toAbsoluteUrl('/media/m99/lang/km.png'),

    },
    {
        lang: 'en',
        name: 'English',
        flag: toAbsoluteUrl('/media/m99/lang/en.png'),

    },
    {
        lang: 'vi',
        name: 'Vietnam',
        flag: toAbsoluteUrl('/media/m99/lang/vn.png'),
    },
    {
        lang: 'zh',
        name: 'Chinese',
        flag: toAbsoluteUrl('/media/m99/lang/cn.png'),

    },

    {
        lang: 'th',
        name: 'Thailand',
        flag: toAbsoluteUrl('/media/m99/lang/th.png'),

    },
    {
        lang: 'lo',
        name: 'Laos',
        flag: toAbsoluteUrl('/media/m99/lang/lo.png'),
    }
]