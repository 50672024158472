import {useEffect} from "react";
import Ticket58 from "./Ticket58";
import {useIntl} from "react-intl";

const TicketModal = ({ticket, onClose}) => {
    useEffect(() => {
        document.body.classList.add('modal-open')
        return () => {
            document.body.classList.remove('modal-open')
        }
    }, [])

    const intl = useIntl().formatMessage;

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_ticket'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                {/* begin::Modal dialog */}
                <div className='modal-dialog modal-dialog-centered mw-350px'>
                    {/* begin::Modal content */}
                    <div className='modal-content'>
                        {/* begin::Modal body */}
                        <div className='modal-body mx-5 mx-xl-15 my-7 d-flex justify-content-center'>
                            <Ticket58 ticket={ticket}/>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                                onClick={onClose}
                            >
                                {intl({id: "CLOSE"})}
                            </button>
                        </div>
                        {/* end::Modal body */}
                    </div>
                    {/* end::Modal content */}
                </div>
                {/* end::Modal dialog */}
            </div>
            {/* begin::Modal Backdrop */}
            <div className='modal-backdrop fade show'></div>
            {/* end::Modal Backdrop */}
        </>
    )
}

export {TicketModal}