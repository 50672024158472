import {AuthModel} from './_models'
import {isIframe} from "../../../../_kiloit/helpers/utils";
import {deleteLocalSt, getLocalSt, setLocalSt} from "../../../../_kiloit/helpers/storage";

const AUTH_LOCAL_STORAGE_KEY = 'auth_key';
const getAuth = (): AuthModel | undefined => {
    if (!localStorage) {
        return
    }

    const lsValue: string | null = getLocalSt(AUTH_LOCAL_STORAGE_KEY)
    if (!lsValue) {
        return
    }

    try {
        const auth: AuthModel = JSON.parse(lsValue) as AuthModel
        if (auth) {
            // You can easily check auth_token expiration also
            return auth
        }
    } catch (error) {
        console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
    }
}

const setAuth = (auth: AuthModel) => {
    if (!localStorage) {
        return
    }

    try {
        const lsValue = JSON.stringify(auth)
        setLocalSt(AUTH_LOCAL_STORAGE_KEY, lsValue)
    } catch (error) {
        console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
    }
}

const removeAuth = () => {
    if (!localStorage) {
        return
    }

    try {
        deleteLocalSt(AUTH_LOCAL_STORAGE_KEY)
    } catch (error) {
        console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
    }
}

export function setupAxios(axios: any) {
    axios.defaults.headers.Accept = 'application/json'
    axios.interceptors.request.use(
        (config: { headers: { Authorization: string } }) => {
            const auth = getAuth();
            if (auth?.token) config.headers.Authorization = `Bearer ${auth?.token}`
            return config
        },
        (err: any) => Promise.reject(err)
    );
    axios.interceptors.response.use((response: any) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    }, (error: any) => {
        try {
            const status = error?.response?.status;
            if (status === 401) {
                removeAuth();
                !isIframe() && window.location.reload();
            }
        } catch (e) {
            console.log(e);
        }

        return Promise.reject(error);
    });
}

const parseToken = () => {
    const token = getAuth()?.token;
    if (!token) return null;
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
};

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY, parseToken}
