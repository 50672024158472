import {createRoot} from 'react-dom/client'
// Axios
import axios from 'axios'
// Apps
import {KiloITI18nProvider} from './_kiloit/i18n/KiloITi18n'
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_kiloit/assets/css/style.rtl.css'
 **/
import './_kiloit/assets/sass/plugins.scss'
import './_kiloit/assets/sass/style.scss'
import './_kiloit/assets/sass/style.react.scss'
import './_kiloit/assets/css/index.css'
import "react-toastify/dist/ReactToastify.css";
import {AppRoutes} from './app/routing/AppRoutes'
import {setupAxios} from './app/modules/auth'
import {ThemeModeProvider} from './_kiloit/partials'
import store from "./app/redux/store";
import {Provider} from "react-redux";

setupAxios(axios)

const container = document.getElementById('root');

if (container) {
    createRoot(container).render(
        <Provider store={store}>
            <KiloITI18nProvider>
                <ThemeModeProvider>
                    <AppRoutes/>
                </ThemeModeProvider>
            </KiloITI18nProvider>
        </Provider>
    )
}
