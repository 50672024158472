/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useLang} from '../../../i18n/KiloITi18n'
import {NavDropdown} from "react-bootstrap";
import {languages} from "../../../constants/language";
import {toAbsoluteUrl} from "../../../helpers";

const M99Languages: FC = () => {
    const {selectedLang, setLang} = useLang()
    const currentLanguage = languages.find((x) => x.lang === selectedLang)
    return (
        <>
            <NavDropdown
                title="" id="basic-nav-dropdown"
                className="rounded-1 img-flag m99-lang" style={{
                backgroundImage: `url('${currentLanguage?.flag}')`,
            }}>
                {
                    languages.filter(lag => lag.lang !== currentLanguage?.lang).map(language => {
                        return <NavDropdown.Item key={language.name}
                                                 className="bg-transparent d-flex justify-content-end"
                                                 onClick={() => {
                                                     setLang(language.lang);
                                                 }}
                        >
                            <img className="radius-3px border" src={toAbsoluteUrl(`${language.flag}`)} alt="" width={60}
                                 height={35}/>
                        </NavDropdown.Item>
                    })
                }
            </NavDropdown>
        </>

    )
}

export {M99Languages}
