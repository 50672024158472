import {useState} from "react";
import {Col, Row} from "react-bootstrap";
import {toAbsoluteUrl} from "../../../../../../_kiloit/helpers";
import {useAppSelector} from "../../../../../redux/hooks/hook";
import {UnderOverHistory} from "./UnderOverHistory";
import {RangeHistory} from "./RangeHistory";

const History = () => {
    const game = useAppSelector(state => state.gameState.game);
    const betCodes = game.statistic.betCodes;
    const [isTop, setIsTop] = useState(true);

    const handleSwapClick = () => {
        setIsTop(prevState => !prevState);
    };

    const renderSumHistory = (startIdx: any, endIdx: any) => {
        return betCodes.slice(startIdx, endIdx).map((bet, index) => (
            <Col key={index} className="p-0" xs={6}>
                <div className="result-summary position-relative">
                    <span className="reult-number position-absolute">{bet.value}</span>
                    <img src={toAbsoluteUrl(`/media/m99/sum-${bet.betCode.toLowerCase()}.svg`)} className="img-fluid"
                         alt="result"/>
                </div>
            </Col>
        ));
    };

    const underOver = isTop ? <UnderOverHistory/> : <RangeHistory/>;
    const range = isTop ? <RangeHistory/> : <UnderOverHistory/>;

    return (
        <div className="bg-primary">
            {range}
            <div className="text-center">
                <Row className="my-2">
                    <Col xs={5}>
                        <Row>
                            {renderSumHistory(0, 2)}
                        </Row>
                    </Col>
                    <Col className="p-0" xs={2}>
                        <div className="result-summary position-relative">
                            <span className="reult-number position-absolute cursor-pointer" onClick={handleSwapClick}>
                                <i className="las la-arrows-alt-v fs-1 text-white"/>
                            </span>
                            <img src={toAbsoluteUrl("/media/m99/swap.svg")} className="img-fluid cursor-pointer"
                                 alt="result" onClick={handleSwapClick}/>
                        </div>
                    </Col>
                    <Col xs={5}>
                        <Row>
                            {renderSumHistory(2, 4)}
                        </Row>
                    </Col>
                </Row>
            </div>
            {underOver}
        </div>
    );
};

export default History;
