function sendPrintToAndroid(toast) {
    /*eslint-disable no-undef*/
    window.Android.showToast(toast);
}

function checkIsAndroid() {
    /*eslint-disable no-undef*/
    return !!window.Android;

}

export {sendPrintToAndroid, checkIsAndroid}