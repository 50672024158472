import Ticket58 from "./Ticket58";
import React, {FC, useRef} from "react";
import ReactToPrint from "react-to-print";
import {checkIsAndroid, sendPrintToAndroid} from "../androidPrint";


const Printer: FC<{
    ticket: any,
    icon?: any
}> = ({ticket, icon}) => {
    const printerRef = useRef<HTMLDivElement | null>();
    if (checkIsAndroid()) {
        return <button onClick={() => sendPrintToAndroid(JSON.stringify(ticket))} className="bg-transparent border-0"
                       id="print-button">
            {icon ? icon : <i className="bi bi-printer fw-bold fs-3 text-info cursor-pointer"/>}
        </button>
    }
    return <>
        <ReactToPrint
            trigger={() => <button className="bg-transparent border-0" id="print-button">
                {icon ? icon : <i className="bi bi-printer fw-bold fs-3 text-info cursor-pointer"/>}
            </button>}
            content={() => printerRef.current!}
        />
        <div className="d-none">
            <div ref={el => (printerRef.current = el)}>
                <Ticket58 ticket={ticket}/>
            </div>
        </div>
    </>
}

export default Printer;