import {setBalance, setLoading} from './reducer';
import {getBalance} from "./_requests";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../redux/hooks/hook";
import {ID} from "../../../_kiloit/helpers";


const useBalance = () => {
    const dispatch = useDispatch();
    const bl = useAppSelector(state => state.balance.balance);

    const getOutBl = (tableId?: ID) => {
        const outBl = bl.outstanding;
        const ubls = outBl?.filter(p => p.productCode === "COCKFIGHT" && p.tableId === tableId) ?? [];
        if (ubls.length > 0) return ubls[0]
        return {balance: 0}
    }

    const fetchBalance = () => {
        dispatch(setLoading(true));
        getBalance().then(res => {
            dispatch(setBalance(res));
            return res;
        })

    }
    return {fetchBalance, getOutBl, bl}
}

export {useBalance}

