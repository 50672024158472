import {ID} from "../../../../_kiloit/helpers";

export interface DrawModel {
    tableId?: number;
    drawCode?: string;
    drawAt?: string;
    drawLabel?: string;
    currentDraw?: TDraw;
    lastDraw?: TDraw;
    statistic?: Statistic
    status?: string,
    liveServer?: LiveServer,
    type?: string,
    liveUrl?: string,
    tableName?: string
}

export interface ListCurrentModel {
    tableId: number,
    tableName: string,
}

export interface TDraw {
    id: ID;
    tableId?: number;
    drawCode: string;
    drawAt: string;
    countDown?: number,
    totalResult: string;
    resultDetail: string;
    detail?: string;
    status: number;
    jackpot: number;
    jackpotType: string;
    items: Item[];
}

export interface Item {
    betCode: string;
    result: null | string;
}


export interface BetModel {
    tableId: ID;
    items: BetItem[];
}

export interface BetItem {
    betCode: string;
    betAmount: number;
    currencyCode?: string;
    betLog?: string;
}

export interface Statistic {
    range: Array<string[]>;
    underOver: Array<string[]>;
    oddEven: Array<string[]>;
    betCodes: BetCodeModel[]
}

export type BetCodeModel = {
    betCode: string;
    value: string;
}

export const StatisticInit: Statistic = {
    range: [],
    underOver: [],
    oddEven: [],
    betCodes: []
}

export interface StatisticParam {
    rangeColumn: number;
    rangeRow: number;
    groupColumn: number;
    groupRow: number;
    rangeSkip?: number;
}

export const initStatisticParam: StatisticParam = {
    rangeColumn: 23,
    rangeRow: 6,
    groupColumn: 15,
    groupRow: 6,
    rangeSkip: 3,
}

export const initListDrawParam: StatisticParam = {
    rangeColumn: 8,
    rangeRow: 6,
    groupColumn: 8,
    groupRow: 6,
}

export const initCurrentBetParam = {
    size: 10
}

export interface CurrentBet {
    tableId: string;
    drawCode: string;
    drawAt: Date;
    drawLabel: string;
    status: number;
    tickets: Ticket[];
    countTicket: number;
    tableName: string;
    results: any;
}

export interface Ticket {
    ticketNumber: string;
    countItem: number;
    totalBetAmount: number;
    totalWinAmount: number;
    items: CItem[];
    createdAt: Date;
}

export interface CItem {
    betCode: string;
    rebateValue: number;
    betAmount: number;
    currencyCode: string;
    isWin: boolean;
    isJackpot: boolean;
    jackpot: number;
    winAmount: number;
    pnLAmount: number;
}

export interface CurrentDrawSocket {
    type: string,
    data: any,
    status: number
}

export type LiveServer = {
    server: Server;
    accessToken: string;
    expirationTime: number;
}

export type Server = {
    protocol: string;
    host: string;
    path: string;
}

export type ChangeRateModel = {
    tableId?: number;
    tableName?: string;
    items: RateItem[];
}

export type RateItem = {
    code: string;
    currencyCode: string;
    payout: number;
    isEnable: boolean;
}


export type ListCurrentBet = Array<CurrentBet>;
export type ListDraw = Array<ListCurrentModel>;



