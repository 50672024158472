import React, {FC} from "react";
import {iframeUrl} from "../../../helpers";
import {useThemeMode} from "../../../partials";
import {useLang} from "../../../i18n/KiloITi18n";
import {getAuth, parseToken} from "../../../../app/modules/auth";
import {env} from "../../../../app/constants/env";

interface Props {
    pathname: string,
    height?: string
    tableId?: string
}

const Iframe: FC<Props> = ({pathname, height = "700px", tableId}) => {
    const {mode} = useThemeMode();
    const merchantId = parseToken()?.merchantId;
    const lang = useLang().selectedLang;
    const param = JSON.stringify({merchantId: merchantId, gameType: "cockfight", tableId: tableId})
    const config = encodeURIComponent(param);
    return <iframe width="100%" height="100%" className="iframe" title="______"
                   src={iframeUrl(pathname + "/" + mode + "/" + lang + "/" + config + `?token=${getAuth()?.token}`)}/>
}


const LiveIframe = ({pathname}) => {
    return <iframe allowFullScreen={true} width="100%" height="100%" className="iframe" title="______"
                   src={env.componentIframe + `/live${pathname}`}/>
}

const ExternalLiveIframe = ({src}) => {
    return <iframe allowFullScreen={true} width="100%" height="100%" className="iframe" title="______"
                   src={src}/>
}


export {Iframe, LiveIframe, ExternalLiveIframe};
