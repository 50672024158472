import React, {FC, useState} from "react";
import {toAbsoluteUrl} from "../../../../../../_kiloit/helpers";
import {useAppSelector} from "../../../../../redux/hooks/hook";
import {getStatus, toK} from "../../../../../../_kiloit/helpers/utils";
import {BetPrice} from "../../../../config/_models";
import {BetItem, BetModel, DrawModel} from "../../../core/_models";
import {useGame} from "../../../core/action";
import {useIntl} from "react-intl";
import {getCurrencySymbol} from "../../../../../../_kiloit/helpers/components/MoneyUtils";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {amounts} from "../../../../../constants/general";
import {I18n} from "../../../../../../_kiloit/i18n/KiloITi18n";
import {NumericFormat} from "react-number-format";

type props = {
    draw?: DrawModel,
}
const BettingBoard: FC<props> = ({draw}) => {

    const intl = useIntl().formatMessage;
    const {betPrize} = useAppSelector(state => state.config);
    const bl = useAppSelector(state => state.balance.balance);
    const game = useAppSelector(state => state.gameState.game);
    const drawCode = game.draw;
    const {postBetting} = useGame();
    const [betAmount, setBetAmount] = useState(0);
    const {getRateByCode} = useGame();
    const status = drawCode.currentDraw?.status;
    const gameStatus = getStatus(status)
    const getTextColor = (status: any) => {
        switch (status) {
            case "OPEN":
                return "text-primary";
            case "CLOSE":
                return "text-danger";
            default:
                return;
        }
    }
    const onBet = (betType: { betCode: string }) => {
        if (betAmount > 0) {
            const bItem: BetItem = {
                betCode: betType.betCode,
                betAmount: betAmount,
                currencyCode: bl.currencyCode,
                betLog: betAmount.toString(),
            }
            const bm: BetModel = {
                tableId: draw?.tableId || 3,
                items: [
                    bItem
                ]
            }

            postBetting(bm).then(() => {
                handleDelete();
            });
        }
        setBetAmount(0)
    }

    const onChangeChip = (chip: BetPrice) => {
        const ba = betAmount + chip.value;
        setBetAmount(ba);
    }

    const handleDelete = () => {
        setBetAmount(0)
        // @ts-ignore

    }

    const BetPriceCard = ({item, index}) => {
        let chipGif = amounts[index];
        return <Row>
            <Col xs={3} className={index > 3 ? 'my-5' : ''}>
                <div
                    onClick={() => onChangeChip(item)}
                    className="m99-betting-container position-relative cursor-pointer"
                    key={index}
                    style={{width: "80px"}}
                >
                    <div className="betting-amount position-absolute top-50 start-50 translate-middle">
                        <div className="row align-items-center">
                            <div className="col">
                                <p className="m-0 text-white fw-bold"
                                   style={{fontSize: "20px"}}>{toK(item.label)}</p>
                            </div>
                        </div>
                    </div>
                    <div className="m99-betting-img-wrapper">
                        <img
                            src={toAbsoluteUrl(`/media/m99/amt${chipGif.toLowerCase()}.svg`)}
                            alt="coin"
                            className="img-fluid rotating-svg"
                            style={{width: '100%'}}
                        />
                    </div>

                </div>
            </Col>
        </Row>
    }

    return <div className="m99-betting-card px-5">
        <div className="card-header bg-black">
            <div className="row">
                <div className="col-6 border-end my-2">
                    <div className="">
                        <h1 className={` ${getTextColor(gameStatus)} text-center text-uppercase my-2 fs-cus-head`}>
                            {intl({id: getStatus(status)})}
                        </h1>
                    </div>
                </div>
                <div className="col-6 my-2">
                    <div className="">
                        <h1 className=" text-warning text-center text-uppercase my-2 fs-cus-head">{intl({id: "FIGHT_NO"})}
                            {" "}#{drawCode?.currentDraw?.drawCode}</h1>
                    </div>
                </div>
            </div>
        </div>

        <Card.Body>
            <div className="">
                <div className="row">
                    <div className="col-6">
                        <Card
                            className="text-center bg-dark bg-black border-danger"
                            style={{borderRadius: "0 20px 0 0"}}>
                            <Card.Header
                                className="bg-danger d-flex justify-content-center align-items-center border-0"
                                style={{borderRadius: "0 20px 0 0"}}>
                                <h1 className="text-white fw-bolder text-uppercase w-100 m-0">MERON</h1>
                            </Card.Header>
                            <Card.Body className="bg-transparent px-4">
                                <p className="text-center my-2">
                                    <span className="text-ratio text-white fw-bold"
                                          style={{fontSize: "3rem"}}>{getRateByCode("red").payout.toFixed(2)}</span>
                                </p>
                                <Button type="button"
                                        onClick={status === 0 ? () => onBet({betCode: "RED"}) : undefined}
                                        className={`btn btn-danger btn-block w-100 fs-3 text-uppercase ${status === 0 ? "" : "div-disabled"}`}>
                                    <i className="las la-plus-circle fs-3"/> {<I18n
                                    t={"BET"}/>}
                                </Button>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-6">
                        <Card
                            className="card-wala text-center bg-black border-primary"
                            style={{borderRadius: "20px 0 0 0 "}}>
                            <Card.Header
                                className="bg-primary d-flex justify-content-center align-items-center border-0"
                                style={{borderRadius: "20px 0 0 0"}}>
                                <h1 className="text-white fw-bold text-uppercase w-100 m-0">WALA</h1>
                            </Card.Header>
                            <Card.Body className="bg-transparent px-4">
                                <p className="text-center my-2"><span
                                    className="text-ratio text-white fw-bold"
                                    style={{fontSize: "3rem"}}>{getRateByCode("blue").payout.toFixed(2)}</span>
                                </p>
                                <Button type="button"
                                        onClick={status === 0 ? () => onBet({betCode: "BLUE"}) : undefined}
                                        className={`btn btn-primary btn-block w-100 fs-3 text-uppercase ${status === 0 ? "" : "div-disabled"}`}>
                                    <i className="las la-plus-circle fs-3"/> {<I18n
                                    t={"BET"}/>}
                                </Button>
                            </Card.Body>
                        </Card>
                    </div>
                    {
                        getRateByCode("draw").isEnable &&
                        <div className="col-12 pt-5"
                             onClick={status === 0 ? () => onBet({betCode: "DRAW"}) : undefined}>
                            <Button type="button"
                                    className={`btn btn-block btn-success w-100 text-uppercase ${status === 0 ? "" : "div-disabled"}`}
                                    style={{fontSize: "1.5rem"}}> Draw <i className="las la-plus-circle fs-1"/> {<I18n
                                t={"BET"}/>} <span
                                className="">{getRateByCode("draw").payout.toFixed(2)}</span>
                            </Button>
                        </div>
                    }

                </div>
                <Form className="betting-form pt-5">
                    <fieldset className="form-group is-valid">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <div
                                    className="input-group-text radius-3px text-warning fs-1 rounded-end-0 fw-bolder bg-black border border-gray-800">{getCurrencySymbol(bl.currencyCode)}
                                </div>
                            </div>
                            {/*<Form.Control type="text" placeholder="Please enter amount"*/}
                            {/*              value={betAmount || 0}*/}
                            {/*              className="form-control bg-black border-danger text-warning fs-3 fw-bold"*/}
                            {/*              onChange={(e) => setBetAmount(Number(e.target.value))}*/}
                            {/*/>*/}
                            <NumericFormat
                                onValueChange={(values) => {
                                    setBetAmount(values.floatValue ?? 0)
                                }}
                                className="form-control bg-black border-danger text-warning fs-1 fw-bold border border-gray-800"
                                value={betAmount} thousandSeparator=","/>
                            <div className="input-group-append">
                                <Button type="button"
                                        className="btn btn-danger rounded-start-0 fs-1 w-80px radius-3px"
                                        onClick={handleDelete}>{intl({id: "DELETE"})}</Button>
                            </div>
                        </div>
                    </fieldset>
                </Form>
                <div className="row justify-content-center mt-5">
                    {betPrize.map((item, index) => {
                        return <div key={index}
                                    className="col-3 p-0 d-flex justify-content-center">
                            <BetPriceCard item={item} index={index} key={index}/>
                        </div>
                    })}
                </div>
            </div>
        </Card.Body>
    </div>
}
export default BettingBoard;