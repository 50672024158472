import {useAppSelector} from "../../../../../redux/hooks/hook";
import M99PrintItems from "./M99PrinterItems";
import {I18n} from "../../../../../../_kiloit/i18n/KiloITi18n";

const M99Ticket58 = ({ticket}) => {

    const {items, status} = ticket;
    const {balance} = useAppSelector(state => state.balance);
    const currencyCode = balance?.currencyCode;

    return <div id="print-58" style={{fontWeight: "bold"}}>
        <table className='print-tb-58 table bg-white' cellPadding="7px">
            <tbody>
            <tr className="border mt-3">
                <td style={{lineHeight: "45px", paddingRight: 0, paddingLeft: 0, fontSize: "28px"}} colSpan={3}
                    className='text-center f-bayon font-weight-bolder'>#{ticket.drawCode} - {<I18n
                    t={items[0].betCode}/>}
                </td>
            </tr>
            {
                items.map((object: any, i: number) => {
                    return <M99PrintItems
                        key={i}
                        lastIndex={items.length - 1 === i}
                        object={object}
                        currency={currencyCode}
                        status={status}
                        isChinese={false}
                        ticket={ticket}
                    />
                })
            }
            <tr>
                <td className="pt-5 font-weight-bolder">-</td>
            </tr>
            </tbody>
        </table>
    </div>
}

export default M99Ticket58;