import {toast, ToastOptions} from "react-toastify";


const showToastError = (message = "", option: ToastOptions = {}) => {
    return toast.error(message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
        theme: "dark",
        ...option
    });
}

const showToastBetSuccess = (message = "", option: ToastOptions = {}) => {
    return toast.success(message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        theme: "light",
        ...option
    });
}

export {showToastError, showToastBetSuccess}