import {FC, useEffect} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {PageDataProvider} from './core'
import {MenuComponent} from '../assets/ts/components'
import {WithChildren} from '../helpers'
import {HeaderWrapper} from "./components/header";
import {platformType} from "../constants/general";

const MasterLayout: FC<WithChildren> = ({children}) => {

    const location = useLocation()
    useEffect(() => {
        setTimeout(() => {
            MenuComponent.reinitialization()
        }, 500)
    }, [])

    useEffect(() => {
        setTimeout(() => {
            MenuComponent.reinitialization()
        }, 500)
    }, [location.key])

    return (
        <PageDataProvider>
            <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
                <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
                    <HeaderWrapper/>
                    <div className={`${platformType?.toLowerCase()}-m-top app-wrapper flex-column flex-row-fluid h-100`}
                         id='kt_app_wrapper'>
                        <div className='app-main flex-column flex-row-fluid h-100' id='kt_app_main'>
                            <div className='d-flex flex-column flex-column-fluid h-100'>
                                <Outlet/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageDataProvider>
    )
}

export {MasterLayout}
