import clsx from "clsx";
import React from "react";

const ChannelSection = ({items, activeTable, onChangeTable}) => {
    return <ul id="fill-tab-live"
               className="mb-1 d-flex justify-content-start"
    >
        {
            items.map((tab, index) => {
                const isActive = tab.tableId === activeTable;
                return <li key={index}
                           onClick={() => {
                               onChangeTable(tab.tableId)
                           }}
                           className={`text-center cursor-pointer fs-4 border w-25 rounded mx-1 ${isActive ? "ch-active opacity-75" : ""}`}>
                    <i className={clsx("fa-solid fa-tv me-2", {
                        "text-success": isActive
                    })}></i>
                    <span>{tab.tableName}</span>
                </li>
            })
        }
    </ul>
}
export default ChannelSection;