import CurrentTicketWrapper from "./CurrentTicketWrapper";
import ChannelWrapper from "../ChannelWrapper";
import BettingBoard from "./BettingBoard";
import {HistoryTable} from "./History";
import RecentBetPage from "../../../../report/sale/components/RecentBetPage";
import React from "react";
import {useAppSelector} from "../../../../../redux/hooks/hook";
import LiveVideoWrapper from "../../live/LiveVideoWrapper";


const DashBoard = () => {

    const {
        tableId,
        draw
    } = useAppSelector(state => state.gameState.game);

    return <div className="row m-0 mt-1">
        <div className="d-none d-lg-block col-md-3  col-lg-3 col-xl-3 p-0">
            <CurrentTicketWrapper tableID={tableId}/>
        </div>
        <div className="col-12 col-md-12 col-lg-9 col-xl-9 position-relative p-0">
            <div className="row m-0">
                <div className="col-12 col-md-12 col-lg-9 col-xl-9 p-0">
                    <ChannelWrapper/>
                    <LiveVideoWrapper draw={draw}/>
                </div>
                <BettingBoard draw={draw}/>
            </div>
            <HistoryTable/>
            <RecentBetPage/>
        </div>
    </div>
}

export default DashBoard;