import axios, {AxiosResponse} from 'axios'
import {
    BetModel,
    ChangeRateModel,
    CurrentBet,
    DrawModel,
    initListDrawParam,
    ListDraw,
    LiveServer,
    Statistic,
    StatisticParam
} from "./_models";
import {apiUrl, ID} from "../../../../_kiloit/helpers";


const CURRENT_DRAW_URL = apiUrl("/draws/current-draw-detail/cockfight/");
const CURRENT_CURRENT_DRAW_URL = apiUrl("/draws/current-draw/cockfight");
const LIST_DRAW_URL = apiUrl("/draws/current-draw-has-statistic/cockfight");
const POST_BETTING = apiUrl("/betting/cockfight");
const GET_STATISTIC = apiUrl("/draws/statistics/cockfight/");
const GET_CURRENT_BET = apiUrl("/reports/recent-betting/cockfight");
const GET_CURRENT_BET_REPORT = apiUrl("/reports/ticket/cockfight?size=100000&page=1&status=paid");
const GET_CHANGE_RATE = apiUrl("/payout-rate");


const getDraw = async (tableId): Promise<DrawModel> => {
    return axios
        .get(CURRENT_DRAW_URL + tableId)
        .then((d: AxiosResponse) => d.data.data)
}

const getListCurrentDraw = async (): Promise<DrawModel> => {
    return axios
        .get(CURRENT_CURRENT_DRAW_URL)
        .then((d: AxiosResponse) => d.data.data)
}

const getLiveServer = async (data: any): Promise<LiveServer> => {
    return axios
        .post("http://13.212.119.126:8909/livestream/tokens", data)
        .then((d: AxiosResponse) => d.data)
}


const postBet = async (payload: BetModel): Promise<AxiosResponse> => {
    const d = await axios
        .post(POST_BETTING, payload);
    return d;
}

const getStatistic = async (tableId: number, param: StatisticParam): Promise<Statistic> => {
    return axios
        .get(GET_STATISTIC + tableId, {params: param})
        .then((d: AxiosResponse) => d.data.data)
}
const getCurrentBet = async (param: {}): Promise<Array<CurrentBet>> => {
    return axios
        .get(GET_CURRENT_BET, {params: param})
        .then((d: AxiosResponse) => d.data.data)
}
const getCurrentBetReport = async (param: {}): Promise<Array<CurrentBet>> => {
    return axios
        .get(GET_CURRENT_BET_REPORT, {params: param})
        .then((d: AxiosResponse) => d.data.data)
}

const getListDraw = async (params = initListDrawParam): Promise<ListDraw> => {
    return axios
        .get(LIST_DRAW_URL, {params: params})
        .then((d: AxiosResponse) => d.data.data)
}

const getChangeRates = async (tableId: ID, params = {}): Promise<ChangeRateModel> => {
    return axios
        .get(GET_CHANGE_RATE.concat(`/${tableId}`), {params: params})
        .then((d: AxiosResponse) => d.data.data)
}


export {
    getDraw,
    postBet,
    getStatistic,
    getCurrentBet,
    getListDraw,
    getLiveServer,
    getListCurrentDraw,
    getChangeRates,
    getCurrentBetReport
}
