import axios, {AxiosResponse} from 'axios'
import {apiUrl} from "../../../../_kiloit/helpers";
import {AuthModel} from "./_models";
import {getAuth} from "./AuthHelpers";
import {checkIsAndroid} from "../../print/androidPrint";
import {env} from "../../../constants/env";


export async function getUserByToken(token = getAuth()?.token) {
    return axios
        .get(apiUrl("/account"), {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((d: AxiosResponse) => d.data)
}

export async function verifiedToken(token: string): Promise<AuthModel> {
    return axios
        .get(apiUrl(`/auth/verify-token/${token}`))
        .then((d: AxiosResponse) => d.data.data)
}


export async function resetPassword(payload = {}) {
    return axios
        .post(apiUrl("/account/reset-password"), payload)
}

export async function login(username: string, password: string): Promise<AuthModel> {
    const res = await axios.post(apiUrl('/auth/login'), {
        username,
        password,
    }, {
        headers: {
            Authorization: `Basic ${env.basicToken}`,
            "X-MerchantId": env.merchantId!,
            "X-App-type": checkIsAndroid() ? "APP" : "WEB"
        }
    });
    return res.data.data;
}

export function requestLogout(): Promise<AuthModel> {
    return axios.post(apiUrl("/account/logout")).then((d: AxiosResponse) => {
        return d.data.data;
    })
}