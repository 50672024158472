import {createSlice} from "@reduxjs/toolkit";
import {AuthModel} from "./_models";
import {getAuth} from "./AuthHelpers";


const initAuth: AuthModel = {
    token: getAuth()?.token ?? null
}

const authSlice = createSlice({
    name: "auth",
    initialState: initAuth,
    reducers: {
        setAuth: (state, action) => {
            state.token = action.payload?.token;
        },
        setUser: (state, action) => {
            state.user = action.payload;
        }
    }
});

export const {setAuth, setUser} = authSlice.actions;
export default authSlice.reducer;