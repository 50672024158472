import _ from "lodash";
import {NumericFormat} from 'react-number-format';

export const currencies = [
    {
        "label": "KHR (៛)",
        "value": "KHR",
        "code": "KHR",
        "symbol": "៛",
        "suffix": "៛"
    },
    {
        "label": "USD ($)",
        "value": "USD",
        "code": "USD",
        "symbol": "$",
        "prefix": "$"
    },
    {
        "label": "THB (฿)",
        "value": "THB",
        "code": "THB",
        "symbol": "฿",
        "suffix": "฿"
    },
    {
        "label": "VND (₫)",
        "value": "VND",
        "code": "VND",
        "symbol": "₫",
        "suffix": "₫"
    }
];

export const getCurrency = (code: any) => {
    return _.find(currencies, obj => obj.code === code);
};

export const moneyFormatter = (amount: any, currency?: any, className = "text-black", isBl = true) => {
    const symbol = getCurrency(currency);
    let cls = amount > 0 ? className : "text-danger";
    if (isBl) cls = "";
    return <NumericFormat
        className={cls}
        value={amount}
        displayType={'text'}
        thousandSeparator={true}
        prefix={symbol?.prefix}
        suffix={symbol?.suffix}/>
};

export function getCurrencySymbol(currencyCode: any) {
    const currency = currencies.find(curr => curr.code === currencyCode);
    return currency ? currency.symbol : null;
}