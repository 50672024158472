import {FC, useEffect, useRef, useState,} from 'react'
import {LayoutSplashScreen} from '../../../../_kiloit/layout/core'
import {getUserByToken} from '../core/_requests'
import {WithChildren} from '../../../../_kiloit/helpers';
import {useAuth} from "../core/action";

const AuthInit: FC<WithChildren> = ({children}) => {
    const {logout, setCurrentUser, auth} = useAuth()
    const didRequest = useRef(false)
    const [showSplashScreen, setShowSplashScreen] = useState(true)
    // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application

    useEffect(() => {
        const requestUser = async () => {
            try {
                if (!didRequest.current) {
                    const {data: {user}} = await getUserByToken()
                    if (user) {
                        setCurrentUser(user)
                    }
                }
            } catch (error) {
                console.error(error)
                if (!didRequest.current) {
                    logout()
                }
            } finally {
                setShowSplashScreen(false)
            }

            return () => (didRequest.current = true)
        }

        if (auth && auth.token) {
            requestUser()
        } else {
            setShowSplashScreen(false)
        }
        // eslint-disable-next-line
    }, [])

    return showSplashScreen ? <LayoutSplashScreen/> : <>{children}</>
}

export {AuthInit}
