import React from "react";
import {useAppSelector} from "../../../../../redux/hooks/hook";
import BettingBoard from "./BettingBoard";
import {HistoryTable} from "./History";
import ChannelWrapper from "../ChannelWrapper";
import LiveVideoWrapper from "../../live/LiveVideoWrapper";


const P99DashBoard = () => {

    const {
        draw
    } = useAppSelector(state => state.gameState.game);

    return <div className="px-0 py-0 px-lg-4 py-lg-4 bg-black-l p99">
        <div className="row m-0">
            <div className="col-12 col-md-12 col-lg-8 col-xl-8 ps-0 pe-0 pe-lg-5">
                <div className="d-block d-lg-none">
                    <ChannelWrapper/>
                </div>
                <LiveVideoWrapper draw={draw}/>
                <div className="d-none d-lg-block mt-4">
                    <HistoryTable/>
                </div>
            </div>
            <BettingBoard draw={draw}/>
        </div>
    </div>
}

export default P99DashBoard;