import moment from "moment";


const getDateDDMMYYYY = (date?: any) => {
    if (date) return moment(date).format("yyyy-MM-DD");
    return moment().format("yyyy-MM-DD");
}

const getFullDateTimePrint = (date: any) => {
    return moment(date).format("YYYY-MM-DD HH:mm:ss")
};

const formatTimeHHMMSS = (date: any) => {
    return moment(date).format("HH:mm:ss")
};

const getFullOrdinalDateWithTime = (dateString: any) => {
    const date = moment(dateString);
    return date.format("MM Do, YYYY, h:mm:ss A");
}

const getFullOrdinalDate = (dateString: any) => {
    const date = moment(dateString);
    return date.format("MM Do, YYYY");
}

export {getDateDDMMYYYY, getFullDateTimePrint, formatTimeHHMMSS, getFullOrdinalDateWithTime, getFullOrdinalDate}