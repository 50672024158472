const chips = ["10", "100", "1K", "5K", "10K"];
const amounts = ['5K', '10K', '20K', '50K', '100K', '200K', '500K', '1M'];

const drawStatus = {
    open: 0,
    drawing: 1,
    released: 2,
    completed: (status: number) => {
        return status === 2 || status === 5
    }
}

export {chips, drawStatus, amounts}