import {FC} from 'react'
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {App} from '../App'
import {PrivateRoutes} from "./PrivateRoutes";
import {AuthPage} from "../modules/auth/AuthPage";
import {VerifyToken} from "../modules/auth/components/VerifyToken";
import {isIframe} from "../../_kiloit/helpers/utils";
import {useAuth} from "../modules/auth/core/action";

const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
    const {currentUser} = useAuth();

    return (
        <BrowserRouter basename={PUBLIC_URL}>
            <Routes>
                <Route element={<App/>}>
                    <Route path='error/*' element={<ErrorsPage/>}/>
                    {currentUser ? (
                        <>
                            <Route path='/*' element={<PrivateRoutes/>}/>
                            <Route path='verify-token/:token' element={<VerifyToken/>}/>
                        </>
                    ) : (
                        <>
                            <Route path='auth/*' element={<AuthPage/>}/>
                            <Route path='verify-token/:token' element={<VerifyToken/>}/>
                            <Route path="*"
                                   element={<Navigate to={!isIframe() ? '/auth/login' : '/verify-token/invalid'}/>}/>
                        </>
                    )}
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export {AppRoutes}
