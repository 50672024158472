/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../helpers'
import {useLang} from '../../../i18n/KiloITi18n'
import {Dropdown} from "react-bootstrap";

const languages = [
    {
        lang: 'vi',
        name: 'Vietnam',
        flag: toAbsoluteUrl('/media/flags/vietnam.svg'),
    },
    {
        lang: 'en',
        name: 'English',
        flag: toAbsoluteUrl('/media/flags/united-states.svg'),
    },
    {
        lang: 'km',
        name: 'Khmer',
        flag: toAbsoluteUrl('/media/flags/cambodia.svg'),
    },

    {
        lang: 'zh',
        name: 'Chinese',
        flag: toAbsoluteUrl('/media/flags/china.svg'),
    },

    {
        lang: 'th',
        name: 'Thailand',
        flag: toAbsoluteUrl('/media/flags/thailand.svg'),
    },
]

const LanguagesCustom: FC = () => {
    const {selectedLang, setLang} = useLang()
    const currentLanguage = languages.find((x) => x.lang === selectedLang)
    return (
        <Dropdown className=" d-flex align-items-center p-0 m-0">
            <Dropdown.Toggle
                className=" bg-transparent cust-btn btn btn-secondary w-100 d-flex rounded-0 align-items-center p-0">
                <span>
                <i className="bi bi-translate fs-1 text-danger p-3"></i>
            </span>
                <span className=' d-flex text-gray-300 fs-6 justify-content-between'>
                            <span
                                className='text-black fw-bold text-start fs-3 me-3'>{currentLanguage?.name}{' '}</span>
                            <span className="symbol symbol-20px symbol-circle">
                                <span className="symbol-label"
                                      style={{backgroundImage: `url('${currentLanguage?.flag}')`,}}/>
                            </span>
                        </span>
            </Dropdown.Toggle>

            <Dropdown.Menu className="bg-light w-250px">
                {languages.map((l) => (
                    <Dropdown.Item className='border-bottom text-dark fw-bold fs-6 py-3'
                                   key={l.lang}
                                   onClick={() => {
                                       setLang(l.lang)
                                   }}
                    >
                        <span
                            className={clsx('', {active: l.lang === currentLanguage?.lang})}>
                                        <span className='symbol symbol-20px me-4'>
                                            <img className='rounded-1' src={l.flag} alt='metronic'/>
                                        </span>
                            {l.name}
                        </span>
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>

    )
}

export {LanguagesCustom}
