import {toAbsoluteUrl} from "./AssetHelpers";

const playAudio = (path: string, isNotPlay = false) => {
    // if (isMute()) return;
    let audio = new Audio(toAbsoluteUrl("/media/audio".concat(path)));
    if (isNotPlay) return audio;
    const playPromise = audio.play();
    if (playPromise !== undefined) {
        playPromise
            .then((_) => {
            })
            .catch((_) => {
            })
            .finally(() => {
                // audio = null;
            });
    }
};


export {playAudio}