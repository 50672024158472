/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {useAppSelector} from "../../../../redux/hooks/hook";
import {gameSetting} from "../../../../constants/config";
import {useConfig} from "../../../config/action";
import {useGame} from "../../core/action";
import {useBalance} from "../../../balance/action";
import {useDispatch} from "react-redux";
import {clearBetting, setCountDown, setDraw} from "../../core/reducer";
import DashBoard from "./s157/DashBoard";
import {platformType, platformTypes} from "../../../../../_kiloit/constants/general";
import P99DashBoard from "./p99/P99DashBoard";
import M99DashBoard from "./m99/M99DashBoard";

const GameWrapper: FC = () => {

    const {
        tableId
    } = useAppSelector(state => state.gameState.game);
    const {bl} = useBalance();

    const {
        fetchCurrentDraw,
        fetchStatistic,
        subscriptionDraw,
        fetchCurrentBet,
        clearAllInterval,
        fetchListCurrentDraw,
        subscriptionRate,
        fetchChangeRate,
        onChangeTable
    } = useGame();
    const {fetchConfig, fetchBetPrize} = useConfig();
    const {fetchBalance} = useBalance();
    const dispatch = useDispatch();

    useEffect(() => {
        fetchBetPrize();
        fetchListCurrentDraw().then(draws => {
            draws[0] && onChangeTable(draws[0].tableId);
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (tableId) {
            fetchCurrentDraw(tableId);
            const refreshData = () => {
                fetchConfig(gameSetting);
                fetchStatistic(tableId);
                fetchBalance();
                fetchCurrentBet({tableId});
                fetchChangeRate(tableId, {currencyCode: bl.currencyCode});
            }
            refreshData();
            subscriptionDraw(`/topic/cockfight:${tableId}`, (_) => {
                refreshData();
            });
            subscriptionRate(`/topic/payout_cockfight:${tableId}:${bl.currencyCode?.toLowerCase()}`)
        }

        return () => {
            dispatch(clearBetting());
            dispatch(setCountDown(-1));
            clearAllInterval();
            dispatch(setDraw({}))
        }
        /*eslint-disable-next-line react-hooks/exhaustive-deps*/
    }, [tableId]);

    switch (platformType) {
        case platformTypes.P99:
            return <P99DashBoard/>
        case platformTypes.M99:
            return <M99DashBoard/>
        default :
            return <DashBoard/>
    }
}

export default GameWrapper;
