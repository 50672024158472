import {FC} from "react";
import {Table} from "react-bootstrap";
import {useAppSelector} from "../../../../../redux/hooks/hook";
import _ from "lodash";

const HistoryTable: FC = () => {
    const statistic = useAppSelector(state => state.gameState.game.statistic);
    return <div id="history-table" className="w-100 max-w-100 overflow-x-scroll border">
        <Table className="border-bottom his-table">
            <tbody>
            {statistic.underOver?.map((e, i) => {
                return <tr key={i}>
                    {e.map((p, j) => {
                        const [code, draw] = p.split("_");
                        return <td key={j} className="text-danger border">
                            <div className="d-flex justify-content-center align-items-center">
                                <div
                                    className={`d-flex justify-content-center align-items-center ${code ? "st-shadow" : ""} tb-aspec st-${_.toLower(code)}`}>
                                    <div
                                        className="d-flex justify-content-center align-items-center p-0 fs-5 fs-lg-2 text-white fw-bold">
                                        {draw}
                                    </div>
                                </div>
                            </div>
                        </td>
                    })}
                </tr>
            })}
            </tbody>
        </Table>
    </div>
}

export {HistoryTable}