import {createSlice} from '@reduxjs/toolkit'
import {BetItem, ChangeRateModel, DrawModel, ListCurrentBet, ListDraw, Statistic, StatisticInit} from "./_models";
import {getLocalSt} from "../../../../_kiloit/helpers/storage";
import {ID} from "../../../../_kiloit/helpers";


// Define a type for the slice state
type drawState = {
    loading: boolean,
    draw: DrawModel,
    statistic: Statistic,
    bet: betState,
    currentBet: {
        list: ListCurrentBet,
        loading: boolean
    },
    currentBetReport: {
        list: ListCurrentBet,
        loading: boolean
    },
    listDraw: ListDraw,
    listCurrentDraw: ListDraw,
    countDown: number,
    messageDraw: string,
    changeRate: ChangeRateModel,
    isAutoPrint: boolean,
    tableId: ID
}
type betState = {
    current: BetItem[]
}


// Define the initial state using that type
let initialState: drawState = {
    loading: false,
    statistic: StatisticInit,
    bet: {
        current: []
    },
    currentBet: {
        list: [],
        loading: false
    },
    currentBetReport: {
        list: [],
        loading: false
    },
    listDraw: [],
    listCurrentDraw: [],
    countDown: 0,
    draw: {},
    messageDraw: "",
    changeRate: {
        items: []
    },
    isAutoPrint: JSON.parse(getLocalSt("isChecked")),
    tableId: undefined
}

export const gameSlice = createSlice({
    name: 'game',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setDraw: (state, action) => {
            state.draw = action.payload;
            state.loading = false;
        },
        setListCurrentDraw: (state, action) => {
            state.listCurrentDraw = action.payload;
            state.loading = false;
        },
        setCurrentDraw: (state, action) => {
            state.draw.currentDraw = action.payload;
        },
        setStatistic: (state, action) => {
            state.statistic = action.payload;
            state.loading = false;
        },
        clearBetting: (state) => {
            state.bet.current = [];
        },
        setBetting: (state, action: { payload: BetItem }) => {
            state.loading = false;
            const data = action.payload;
            let cList = state.bet.current;
            let list = state.bet.current.filter(e => e.betCode === data.betCode);
            if (list.length > 0) {
                cList.map(e => {
                    if (e.betCode === data.betCode)
                        e.betAmount += data.betAmount;
                    return e;
                });
                state.bet.current = cList;
            } else {
                state.bet.current.push(action.payload)
            }
        },
        setCurrentBet: (state, action) => {
            state.currentBet.list = action.payload;
            state.currentBet.loading = false;
        },
        setCurrentBetReport: (state, action) => {
            state.currentBetReport.list = action.payload;
            state.currentBetReport.loading = false;
        },
        setCurrentBetLoading: (state, action) => {
            state.currentBet.loading = action.payload;
        },
        setCurrentBetReportLoading: (state, action) => {
            state.currentBetReport.loading = action.payload;
        },
        setListDraw: (state, action) => {
            state.listDraw = action.payload;
            state.loading = false;
        },
        setCountDown: (state, action) => {
            state.countDown = action.payload;
        },
        setMessageDraw: (state, action) => {
            state.messageDraw = action.payload;
        },
        setChangeRate: (state, action) => {
            state.changeRate = action.payload;
        },
        setChangeRateItem: (state, action) => {
            state.changeRate.items = action.payload;
        },
        setAutoPrint: (state, action) => {
            state.isAutoPrint = action.payload;
        }
        ,
        setActiveTable: (state, action) => {
            state.tableId = action.payload;
        }
    }
})

export const {
    setMessageDraw,
    setLoading,
    setDraw,
    setStatistic,
    setBetting,
    setCurrentBet,
    setCurrentBetReportLoading,
    setCurrentBetReport,
    setListDraw,
    setCountDown,
    clearBetting,
    setCurrentBetLoading,
    setCurrentDraw,
    setListCurrentDraw,
    setChangeRate,
    setChangeRateItem,
    setAutoPrint,
    setActiveTable
} = gameSlice.actions


export default gameSlice.reducer
