import {useAppSelector} from "../../../../../redux/hooks/hook";
import React from "react";
import {useIntl} from "react-intl";
import _ from "lodash";
import {drawStatus} from "../../../../../constants/general";
import {formatTimeHHMMSS} from "../../../../../../_kiloit/helpers/dateUtils";
import {moneyFormatter} from "../../../../../../_kiloit/helpers/components/MoneyUtils";
import {gameResults, gameResultType} from "../../../../../constants/game";

const RecentBet = ({setTicket}) => {
    let {list} = useAppSelector(state => state.gameState.game.currentBetReport);
    const intl = useIntl().formatMessage;


    return <div className="h-360px min-h-360px bg-black overflow-auto">
        {_.isEmpty(list) ? <div className="d-flex h-100 fs-3 text-warning justify-content-center align-items-center">
                {intl({id: "NO_RECORD"})}
            </div> :
            <table className="table text-white fw-bold fs-5">
                <thead>
                <tr className="fw-bold fs-5 border-bottom ff-hanunam">
                    <th>{intl({id: "FIGHT_NO"})}</th>
                    <th> {intl({id: "BET_ON"})}</th>
                    <th> {intl({id: "WIN.LOSE"})}</th>
                    <th> {intl({id: "TIME"})}</th>
                    <th> {intl({id: "TICKET"})}</th>
                </tr>
                </thead>
                <tbody>

                {list.map((draw) => {
                    return draw.tickets.map((ticket, i) => {
                        const status = drawStatus.completed(draw.status);
                        const ts = {
                            ...ticket,
                            channel: draw.tableName,
                            status: status,
                            drawLabel: draw.drawLabel,
                            drawCode: draw.drawCode
                        };
                        const isDraw = draw?.results.find(e => e.betCode === gameResultType.SLOT).result === gameResults.DRAW;
                        return ticket.items.map((item, j) => {
                            return <tr key={`${i}+${j}`} className="border-bottom">
                                <td className="text-gray-500">{draw.drawCode}</td>
                                <td>
                                    <div
                                        className={`w-25 st-text-${item.betCode.toLowerCase()}`}>{intl({id: `${item.betCode}`})}</div>
                                </td>
                                <td>
                                    {item.isWin ? <div
                                            className="text-blue-d fw-bold">{moneyFormatter(item.betAmount)} x {item.rebateValue.toFixed(2)} : {moneyFormatter(item.winAmount)}</div>
                                        : isDraw ? <div
                                                className="fw-bold text-blue-d">{moneyFormatter(item.betAmount)}</div> :
                                            <div
                                                className="fw-bold text-red-d">L(-{moneyFormatter(item.betAmount)})</div>
                                    }

                                </td>
                                <td className="text-gray-500">{formatTimeHHMMSS(ticket.createdAt)}</td>
                                <td>
                                    <div onClick={() => setTicket(ts)}
                                         className="text-white fw-bold cursor-pointer">{intl({id: "PRINT"})}</div>
                                </td>
                            </tr>
                        })
                    })
                })}
                </tbody>
            </table>
        }

    </div>
}

export default RecentBet;