import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_kiloit/layout/MasterLayout'
import {routes} from "./routes";


const PrivateRoutes = () => {
    return (
        <Routes>
            <Route element={<MasterLayout/>}>
                {routes.map((route, i) => {
                    if (route.redirect)
                        return <Route key={i} path={route.path}
                                      element={<Navigate to={route.redirect}/>}/>
                    return <Route key={i} path={route.path} element={<route.component/>}/>
                })}
                <Route path='*' element={<Navigate to='/'/>}/>
            </Route>
        </Routes>
    )
}

// const SuspensedView: FC<WithChildren> = ({children}) => {
//     const baseColor = getCSSVariableValue('--kt-primary')
//     TopBarProgress.config({
//         barColors: {
//             '0': baseColor,
//         },
//         barThickness: 1,
//         shadowBlur: 5,
//     })
//     return (
//         <Suspense fallback={<TopBarProgress/>}>
//             <DisableSidebar>{children}</DisableSidebar>
//         </Suspense>
//     )
// }

export {PrivateRoutes}
