import React from "react";
import PrintItems from "./PrintItems";
import {moneyFormatter} from "../../../_kiloit/helpers/components/MoneyUtils";
import {getFullDateTimePrint} from "../../../_kiloit/helpers/dateUtils";
import {useIntl} from "react-intl";
import {useAppSelector} from "../../redux/hooks/hook";

const Ticket58 = ({ticket}) => {

    const intl = useIntl().formatMessage;
    const {ticketNumber, createdAt, items, status, totalBetAmount, drawLabel} = ticket;
    const {balance} = useAppSelector(state => state.balance);
    const currencyCode = balance?.currencyCode

    return <div id="print-58" style={{fontWeight: "bold"}}>
        <table className='print-tb-58 table bg-white' cellPadding="7px">
            <tbody>
            <tr className="bb-solid-1">
                <td style={{lineHeight: "45px", paddingRight: 0, paddingLeft: 0, fontSize: "34px"}} colSpan={3}
                    className='text-center f-bayon font-weight-bolder'>Cock Fighting
                </td>
            </tr>
            <tr>
                <td className="f-bayon text-center bb-solid-1 p-0 l-h-25">
                    <div
                        className="f-bayon f-22 text-center align-items-center p-0 font-weight-bolder">{getFullDateTimePrint(createdAt)}</div>
                    <div style={{letterSpacing: "-0.5px"}}
                         className="f-bayon f-23 p-0 font-weight-bolder">#{ticketNumber} / {drawLabel}</div>
                </td>
            </tr>
            {
                items.map((object: any, i: number) => {
                    return <PrintItems
                        key={i}
                        lastIndex={items.length - 1 === i}
                        object={object}
                        currency={currencyCode}
                        status={status}
                        isChinese={false}
                        ticket={ticket}
                    />
                })
            }
            <tr>
                <td className="text-center f-bayon font-weight-bold f-26 bb-solid-1" colSpan={3}>
                    {intl({id: "TOTAL"})} : {moneyFormatter(totalBetAmount, currencyCode, "text-black font-weight-bolder f-26 f-bayon")}
                </td>
            </tr>
            <tr>
                <td className="pt-5 font-weight-bolder">-</td>
            </tr>
            </tbody>
        </table>
    </div>
}

export default Ticket58;