import GameWrapper from "../modules/game/components/dashboard/GameWrapper";
import ChangePassword from "../modules/auth/components/ChangePassword";
import ReportPage from "../modules/report/ReportPage";
import AppReportPage from "../modules/report/sale/app/AppReportPage";
import RecentBetPage from "../modules/report/sale/components/RecentBetPage";


type RouteMl = {
    path: string,
    component?: any,
    redirect?: string,
    exact?: boolean | false
}
const routes: Array<RouteMl> = [
    {
        path: "/",
        component: GameWrapper,
        exact: true
    },

    {
        path: "lobby/:id/statement",
        component: RecentBetPage,
    },


    {
        path: "app-report/:tableId",
        component: AppReportPage,
    },
    {
        path: "/change-pw",
        component: ChangePassword,
        exact: true
    },
    {
        path: "report/:tableId",
        component: ReportPage,
    },
]

export {routes}