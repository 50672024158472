import {createSlice} from "@reduxjs/toolkit";
import {SaleReportModel} from "./_model";

type  reportState = {
    saleReport: {
        list: SaleReportModel[],
        loading: boolean,
    }
}

const initialState: reportState = {
    saleReport: {
        list: [],
        loading: false,
    }
}

export const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        setLoadingSale: (state, action) => {
            state.saleReport.loading = action.payload;
        },
        setSaleReport: (state, action) => {
            state.saleReport.list = action.payload.data;
            state.saleReport.loading = false
        }
    }
});

export const {
    setSaleReport,
    setLoadingSale
} = reportSlice.actions;
export default reportSlice.reducer;