import {getAuth} from "../modules/auth";
import * as StompJs from "@stomp/stompjs";
import {StompConfig} from "@stomp/stompjs";
import {env} from "../constants/env";

let client: SocketService | null;

const getClient = () => {
    if (!getAuth()?.token) {
        client = null;
        return;
    }
    if (client == null) {
        client = new SocketService();
    }
    client._connect(false);
    return client;
}

const initClient = () => {
    client = new SocketService();
}

export {
    getClient,
    initClient
}

export class SocketService {

    webSocketEndPoint = env.socketUrl;
    stompClient: StompJs.Client | null | undefined;
    stompSubscribe: StompJs.StompSubscription | null | undefined;

    stompConfig: StompConfig = {
        // Typically, login, passcode and vhost
        // Adjust these for your broker
        connectHeaders: {
            Authorization: getAuth()?.token ?? ""
        },

        // Broker URL, should start with ws:// or wss:// - adjust for your broker setup
        brokerURL: this.webSocketEndPoint,

        // If disconnected, it will retry after 10 sec
        reconnectDelay: 100000,
        heartbeatIncoming: 5000,
        heartbeatOutgoing: 0,
    };

    _connect(isSub: boolean, topic?: any, onMessageReceived?: any) {
        if (this.stompClient) return;
        // console.log("Initialize WebSocket Connection");
        this.stompConfig.connectHeaders!.Authorization = getAuth()?.token ?? "";
        this.stompClient = new StompJs.Client(this.stompConfig);
        this.stompClient.activate();
        const _this = this;
        this.stompClient.onConnect = function (frame) {
            isSub && _this._subscribe(topic, onMessageReceived);
        };
    };

    _unsubscribe() {
        if (this.stompSubscribe) {
            this.stompSubscribe.unsubscribe();
            this.stompSubscribe = null;
        }
    }

    _startSubscribe(topic: string, onMessageReceived: { (ms: any): void; (ms: any): void; }) {
        this._unsubscribe();
        if (!this.stompClient) {
            // console.log("!isConnected")
            return this._connect(true, topic, onMessageReceived)
        }
        // console.log("isConnected")
        return this._subscribe(topic, onMessageReceived)
    }

    _subscribe(topic: string, onMessageReceived: { (ms: any): void; (ms: any): void; (arg0: any): any; }) {
        const _this = this;
        // console.log("start---sub------>", topic)
        setTimeout(() => {
            _this.stompSubscribe = _this.stompClient?.subscribe(topic, function (sdkEvent) {
                // console.log("------", sdkEvent.body)
                onMessageReceived && onMessageReceived(JSON.parse(sdkEvent.body));
            });
        }, 500)
    }

    // on error, schedule a reconnection attempt
    errorCallBack(error: string) {
        console.log("socket error -> " + error)
    }

}
