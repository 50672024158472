import React from "react";
import {useAppSelector} from "../../../../redux/hooks/hook";
import {useGame} from "../../core/action";
import {platformType, platformTypes} from "../../../../../_kiloit/constants/general";
import ChannelSection from "./s157/ChannelSection";
import P99ChannelSection from "./p99/P99ChannelSection";
import M99ChannelSection from "./m99/M99ChannelSection";


const ChannelWrapper = () => {

    const {listCurrentDraw, tableId} = useAppSelector(state => state.gameState.game);
    const {onChangeTable} = useGame();


    switch (platformType) {
        case platformTypes.P99:
            return <P99ChannelSection items={listCurrentDraw} activeTable={tableId} onChangeTable={onChangeTable}/>
        case platformTypes.M99:
            return <M99ChannelSection items={listCurrentDraw} activeTable={tableId} onChangeTable={onChangeTable}/>
        default :
            return <ChannelSection items={listCurrentDraw} activeTable={tableId} onChangeTable={onChangeTable}/>
    }
}

export default ChannelWrapper;