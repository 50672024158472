import axios, {AxiosResponse} from 'axios'
import {apiUrl} from "../../../_kiloit/helpers";
import {Balance} from "./_models";

const getBalance = async (param: any = {}): Promise<Balance> => {
    return axios
        .post(apiUrl("/balance"), {
            params: param
        }).then((d: AxiosResponse) => d.data.data)
}

export {
    getBalance
}
