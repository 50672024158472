import {platformType, platformTypes} from "../../../constants/general";
import {P99Header} from "./P99Header";
import {Header} from "./Header";
import {M99Header} from "./M99Header";

const HeaderWrapper = () => {
    switch (platformType) {
        case  platformTypes.P99:
            return <P99Header/>;
        case platformTypes.M99:
            return <M99Header/>
        default:
            return <Header/>
    }
}

export {HeaderWrapper};