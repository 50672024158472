import {
    clearBetting,
    setActiveTable,
    setAutoPrint,
    setBetting,
    setChangeRate,
    setChangeRateItem,
    setCurrentBet,
    setCurrentBetLoading,
    setCurrentBetReport,
    setCurrentBetReportLoading,
    setCurrentDraw,
    setDraw,
    setListCurrentDraw,
    setListDraw,
    setLoading,
    setMessageDraw,
    setStatistic
} from './reducer';
import {
    getChangeRates,
    getCurrentBet,
    getCurrentBetReport,
    getDraw,
    getListCurrentDraw,
    getListDraw,
    getStatistic,
    postBet
} from "./_requests";
import {
    BetModel,
    DrawModel,
    initCurrentBetParam,
    initStatisticParam,
    Item,
    RateItem,
    StatisticParam,
    TDraw
} from "./_models";
import {showToastBetSuccess, showToastError} from "../../../../_kiloit/helpers/Toast";
import {getClient} from "../../../services/SocketService";
import {addClassById, clickId, scrollLeft, scrollTop} from "../../../../_kiloit/helpers/utils";
import {drawStatusSocket} from "../../../constants/config";
import {useDispatch} from "react-redux";
import {useBalance} from "../../balance/action";
import {AnyAction, ThunkAction} from "@reduxjs/toolkit";
import {RootState} from "../../../redux/store";
import {toast} from "react-toastify";
import {playAudio} from "../../../../_kiloit/helpers/Audio";
import {ID} from "../../../../_kiloit/helpers";
import {useAppSelector} from "../../../redux/hooks/hook";
import {getCSize, setLocalSt} from "../../../../_kiloit/helpers/storage";
import {getDateDDMMYYYY} from "../../../../_kiloit/helpers/dateUtils";
import {platformType} from "../../../../_kiloit/constants/general";
import {getRecentBetType} from "../../../constants/game";


let drawSubscribe;
let rateSubscribe;
const useGame = () => {
    const dispatch = useDispatch();
    let countDownInv: NodeJS.Timeout;
    const {fetchBalance} = useBalance();
    // get socket client instant
    const {changeRate: {items}, isAutoPrint} = useAppSelector(state => state.gameState.game);


// post api create betting
    const postBetting = async (payload: BetModel) => {
        return postBet(payload).then(res => {
            dispatch(setBetting(payload.items[0]));
            fetchBalance();
            fetchCurrentBet({size: getCSize(), tableId: payload.tableId}).then(() => {
                setTimeout(() => {
                    isAutoPrint && clickId("print-button");
                }, 500)
            });
            showToastBetSuccess("Bet Success.", {
                position: toast.POSITION.TOP_CENTER
            });
            scrollTop("current-bet-report")
            return res
        }).catch((error) => {
            showToastError(error.response.data.message, {
                position: toast.POSITION.TOP_CENTER
            })
            return error
        })
    }


// fetch api current draw
    const fetchCurrentDraw = (params: number | undefined) => {
        dispatch(setLoading(true))
        getDraw(params).then(res => {
            const data = res;
            dispatch(setDraw(data));
            checkDraw(data.currentDraw!);
        })
    }

    // fetch api current draw
    const fetchListCurrentDraw = () => {
        dispatch(setLoading(true))
        return getListCurrentDraw().then(res => {
            dispatch(setListCurrentDraw(res));
            return res
        })
    }


// check draw status if release or open
    const checkDraw = (currentDraw: TDraw) => {
        if (currentDraw?.countDown! > 0 && currentDraw?.status === 0) {
            dispatch(clearBetting());
        }
        dispatch(setCurrentDraw(currentDraw));
    }

    const checkDrawManual = (currentDraw: TDraw, drawStatus: string, isSocket = false) => {
        dispatch(setMessageDraw(drawStatus));
        if (drawStatus === drawStatusSocket.release) {
            setWinAnimate(currentDraw.items)
        }
        if (drawStatus === drawStatusSocket.open) {
            isSocket && openMessage();
            dispatch(clearBetting());
        }
    }

// show message draw message
    const openMessage = () => {
        addClassById({
            id: "sd-gt-open",
            classAdd: "message-jump",
            removedAfter: 2000,
        });
    }


// clear all interval
    const clearAllInterval = () => {
        countDownInv && clearInterval(countDownInv);
    }

// set win animation background color
    const setWinAnimate = (items: Item[], delay = 5) => {
        items.forEach((item) => {
            if (item.result !== "NONE" && item.result !== null) {
                const id = `slot-${item.result}`;
                addClassById({
                    id: id,
                    classAdd: "sd-win-animate",
                    removedAfter: delay * 1000
                });
            }
        });
    }


// fetch api statistic
    const fetchStatistic = (tableId: number, params: StatisticParam = initStatisticParam) => {
        getStatistic(tableId, params).then(res => {
            dispatch(setStatistic(res));
            scrollLeft("history-table");
        });
    }

// fetch api current betting
    const fetchCurrentBet = async (params = {}) => {
        dispatch(setCurrentBetLoading(true));
        return getCurrentBet({
            ...initCurrentBetParam, type: getRecentBetType, ...params
        }).then(res => {
            dispatch(setCurrentBet(res));
            return res;
        });
    }

    const fetchCurrentBetReport = async () => {
        dispatch(setCurrentBetReportLoading(true));
        return getCurrentBetReport({...initCurrentBetParam, date: getDateDDMMYYYY()}).then(res => {
            dispatch(setCurrentBetReport(res));
            return res;
        });
    }


// fetch api list all  game tables
    const fetchListDraw = async () => {
        dispatch(setLoading(true));
        const res = await getListDraw();
        dispatch(setListDraw(res));
        return res;
    }

// fetch api list all change rates
    const fetchChangeRate = async (tableId: ID, params = {}) => {
        const res = await getChangeRates(tableId, params);
        dispatch(setChangeRate(res));
        return res;
    }


// start subscription by topic & return callback message
    const subscriptionDraw = (topic: string, callback: ((arg0: any) => any) | undefined) => {
        const client = getClient();
        setTimeout(() => {
            drawSubscribe && drawSubscribe.unsubscribe();
            drawSubscribe = client?.stompClient?.subscribe(topic, function (sdkEvent) {
                const ms = JSON.parse(sdkEvent.body);
                const currentDraw: TDraw = JSON.parse(ms.data);
                checkDraw(currentDraw);
                //@ts-ignore
                setLocalSt('currentDrawDetail', currentDraw.detail);
                if (ms.type === drawStatusSocket.open || ms.type === drawStatusSocket.cashOut) callback && callback(ms);
                if (ms.type === drawStatusSocket.release) {
                    playAudio(`/${platformType}/${currentDraw.detail}.mp3`);
                }
            });
        }, 500);
    }

    const subscriptionRate = (topic: string) => {
        const client = getClient();
        setTimeout(() => {
            rateSubscribe && rateSubscribe.unsubscribe();
            rateSubscribe = client?.stompClient?.subscribe(topic, function (sdkEvent) {
                const items: RateItem = JSON.parse(sdkEvent.body);
                dispatch(setChangeRateItem(items));
            });
        }, 500);
    }

    const getRateByCode = (code: string): RateItem => {
        const item = items.find(item => item.code === code);
        if (item) {
            return item;
        }
        return {
            payout: 0,
            isEnable: true,
            code: "",
            currencyCode: ""
        };
    }

// check and updated list table by tableId from socket
    const checkListDrawAndUpdate = (cd: any): ThunkAction<void, RootState, unknown, AnyAction> => {
        return async (dispatch, getState) => {
            const ld = getState().gameState.game.listDraw;
            let listDraw = ld.map((draw: DrawModel) => {
                if (draw.tableId === cd.tableId) {
                    return cd;
                }
                return draw;
            });
            dispatch(setListDraw(listDraw));
        }
    }

    const handleCheckboxChange = () => {
        dispatch(setAutoPrint(!isAutoPrint))
        setLocalSt("isChecked", JSON.stringify(!isAutoPrint));
    };

    const onChangeTable = (tableId: ID) => dispatch(setActiveTable(tableId));

// unSubscription socket
    const unSubscriptionGame = () => getClient()?._unsubscribe();


    return {
        postBetting,
        checkDraw,
        fetchCurrentDraw,
        fetchStatistic,
        fetchCurrentBet,
        fetchListDraw,
        subscriptionDraw,
        unSubscriptionGame,
        clearAllInterval,
        checkListDrawAndUpdate,
        checkDrawManual,
        fetchListCurrentDraw,
        subscriptionRate,
        fetchChangeRate,
        getRateByCode,
        isAutoPrint,
        handleCheckboxChange,
        onChangeTable,
        fetchCurrentBetReport
    }
}

export {
    useGame
}
