import {FC} from "react";
import {Table} from "react-bootstrap";
import {useAppSelector} from "../../../../../redux/hooks/hook";
import {toAbsoluteUrl} from "../../../../../../_kiloit/helpers";

const UnderOverHistory: FC = () => {
    const statistic = useAppSelector(state => state.gameState.game.statistic);
    return <div id="history-table" className="w-100 max-w-100 overflow-x-scroll border bg-light">
        <Table className="border-bottom his-table">
            <tbody>

            {statistic.underOver?.map((e, i) => {
                return <tr key={i}>
                    {e.map((p, j) => {

                        const [code, draw] = p.split("_");
                        return <td key={j} className="text-danger border">
                            <div className="d-flex justify-content-center align-items-center">
                                <div className="result-summary position-relative">
                                    <span className="reult-number position-absolute">{draw}</span>
                                    {code ? <img
                                        src={toAbsoluteUrl(`/media/m99/sum-${code.toLowerCase()}.svg`)}
                                        alt="coin"
                                        className="img-fluid"
                                        style={{width: '48px'}}
                                    /> : <div
                                        className={`d-flex justify-content-center align-items-center tb-aspec`}>
                                        <div
                                            className="d-flex justify-content-center align-items-center p-0 fs-5 fs-lg-2 text-white fw-bold">
                                            {draw}
                                        </div>
                                    </div>}
                                </div>

                            </div>
                        </td>
                    })}
                </tr>
            })}
            </tbody>
        </Table>
    </div>
}

export {UnderOverHistory}