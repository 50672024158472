import axios, {AxiosResponse} from 'axios'
import {apiUrl} from "../../../_kiloit/helpers";
import {Config} from "./_models";

const getConfig = async (param: any = {}): Promise<Config> => {
    return axios
        .get(apiUrl("/config"), {
            params: param
        })
        .then((d: AxiosResponse) => d.data.data)
}
const getBetPrize = async (param: any = {}): Promise<Config> => {
    return axios
        .get(apiUrl("/bet-prize?productCode=cockfight"), {
            params: param
        })
        .then((d: AxiosResponse) => d.data.data)
}

export {
    getConfig,
    getBetPrize
}
