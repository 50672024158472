import {env} from "../../app/constants/env";

const platformType = env.platformType;

const platformTypes = {
    P99: "P99",
    M99: "M99",
    S157: "S157",
}

const currencySign = {
    "KHR": "៛",
    "USD": "$"
}

export {platformType, platformTypes, currencySign}