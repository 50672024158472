import clsx from "clsx";
import React from "react";

const P99ChannelSection = ({items, activeTable, onChangeTable}) => {
    return <ul id="fill-tab-live"
               className="mb-1 d-flex justify-content-start channel"
    >
        {
            items.map((tab, index) => {
                const isActive = tab.tableId === activeTable;
                return <li key={index}
                           onClick={() => {
                               onChangeTable(tab.tableId)
                           }}
                           className={clsx("w-50 w-lg-unset text-center bg-dark cursor-pointer fs-channel px-6 py-1", {
                               "bg-yellow": isActive,
                           })}>
                    <i className={clsx("text-white fa-solid fa-tv me-2 fs-channel  position-relative", {
                        "text-black active": isActive
                    })}></i>
                    <span className={clsx("text-white", {
                        "text-black": isActive,
                    })}>{tab.tableName}</span>
                </li>
            })
        }
    </ul>
}
export default P99ChannelSection;