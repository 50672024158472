import React, {useState} from "react";
import clsx from "clsx";

const RefreshBtn = ({onRefresh, className = ""}) => {
    const [loading, setLoading] = useState(false);

    const onStartRefresh = async () => {
        setLoading(true);
        await onRefresh();
        setTimeout(() => {
            setLoading(false)
        }, 200)
    }
    return <div>
        {loading ?
            <span className='spinner-border spinner-border-sm align-middle fs-2 mx-4'/>
            : <i onClick={onStartRefresh}
                 className={clsx("bi bi-arrow-counterclockwise fs-2 fw-bold text-white mx-4  cursor-pointer " + className)}/>
        }

    </div>
}

export default RefreshBtn;