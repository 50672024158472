import React, {FC, useEffect, useState} from 'react';
import RecentBet from "./RecentBet";
import {useParams} from "react-router-dom";
import {useGame} from "../../../game/core/action";
import {useBalance} from "../../../balance/action";
import {useAppSelector} from "../../../../redux/hooks/hook";

const RecentBetPage: FC = () => {

    const {fetchCurrentBet} = useGame()
    const {fetchBalance} = useBalance();
    const draw = useAppSelector(state => state.gameState.game.draw);

    const param = useParams();
    const tableId = draw.tableId ?? parseInt(param?.tableId ?? "")
    const [size, setSize] = useState(3)

    useEffect(() => {
        fetchBalance();
        fetchCurrentBet({size, tableId: tableId});
        // eslint-disable-next-line
    }, [])

    return (
        <div className="d-lg-none">
            <RecentBet size={size} setSize={setSize}/>
        </div>
    );
};

export default RecentBetPage;