import React, {FC} from "react";
import {ExternalLiveIframe} from "../../../../../_kiloit/layout/components/iframe/Iframe";

const ExternalLiveVideoWrapper: FC<{
    src: string
}> = ({src}) => {
    return <div
        className="bg-black vid-border video-aspect-ratio d-flex align-items-center position-relative justify-content-center">
        <ExternalLiveIframe src={src}/>
    </div>
}
export default ExternalLiveVideoWrapper;