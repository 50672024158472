import React from "react";
import PrintItems from "./PrintItems";
import {useAppSelector} from "../../../redux/hooks/hook";

const Ticket58 = ({ticket}) => {

    const {items, status} = ticket;
    const {balance} = useAppSelector(state => state.balance);
    const currencyCode = balance?.currencyCode

    return <div id="print-58" style={{fontWeight: "bold"}}>
        <table className='print-tb-58 table bg-white' cellPadding="7px">
            <tbody>
            <tr className="bb-dash-1">
                <td style={{lineHeight: "45px", paddingRight: 0, paddingLeft: 0, fontSize: "34px"}} colSpan={3}
                    className='text-center f-bayon text-decoration-underline fw-light'>P99 Cock
                </td>
            </tr>
            {
                items.map((object: any, i: number) => {
                    return <PrintItems
                        key={i}
                        lastIndex={items.length - 1 === i}
                        object={object}
                        currency={currencyCode}
                        status={status}
                        isChinese={false}
                        ticket={ticket}
                    />
                })
            }
            <tr className="bb-dash-1">
                <td colSpan={3}
                    className='text-center f-bayon font-weight-bolder pt-0'>
                </td>
            </tr>
            <tr className="">
                <td className="pt-0 font-weight-bolder pb-0">{""}</td>
            </tr>
            </tbody>
        </table>
    </div>
}

export default Ticket58;