import React, {useEffect, useState} from "react";
import {useAppSelector} from "../../../../redux/hooks/hook";
import {moneyFormatter} from "../../../../../_kiloit/helpers/components/MoneyUtils";
import {TicketModal} from "../../../print/TicketModal";
import Printer from "../../../print/Printer";
import {useIntl} from "react-intl";
import _ from "lodash";
import {toAbsoluteUrl} from "../../../../../_kiloit/helpers";
import {getColor} from "../../../../../_kiloit/helpers/utils";
import {drawStatus} from "../../../../constants/general";
import {NumericFormat} from "react-number-format";
import {I18n} from "../../../../../_kiloit/i18n/KiloITi18n";
import {getCSize, setLocalSt, stKeys} from "../../../../../_kiloit/helpers/storage";
import RefreshBtn from "../../../../../_kiloit/partials/widgets/RefreshButton";
import {useGame} from "../../../game/core/action";
import {useParams} from "react-router-dom";


const RecentBet = ({size, setSize}) => {

    const intl = useIntl().formatMessage;
    const {list} = useAppSelector(state => state.gameState.game.currentBet);
    const draw = useAppSelector(state => state.gameState.game.draw);
    const [ticketDl, setTicket] = useState<any>();
    const {balance} = useAppSelector(state => state.balance);
    const currencyCode = balance?.currencyCode
    const param = useParams();
    const tableId = draw.tableId ?? parseInt(param?.tableId ?? "")

    const {fetchCurrentBet, handleCheckboxChange, isAutoPrint} = useGame()
    useEffect(() => {
        setSize(getCSize());
    }, [setSize])

    const onChangeSize = (e) => {
        setLocalSt(stKeys.currentBet, e);
        setSize(e);
    }

    const onFilter = () => {
        fetchCurrentBet({size, tableId})
    }


    return <div id="current-bet-report" className="h-450px h-xl-500px overflow-y-scroll">

        <div className="d-flex justify-content-between align-items-center text-light bg-dark p-2">
            <span className="text-light fw-bold fs-3"><I18n t={"RECENT.BET"}/></span>

            <NumericFormat
                className="fs-3 fw-bold in-recent-size text-center ms-auto w-50px"
                // @ts-ignore
                isAllowed={({value}) => value < 11}
                value={size}
                onBlur={onFilter}
                onValueChange={(e) => {
                    onChangeSize(e.value)
                }}

            />

            <div className="ms-auto">
                <i
                    className={`bi bi-printer fw-bold fs-3 cursor-pointer text-white px-3 rounded ${isAutoPrint ? "bg-info" : ""}`}
                    onClick={() => handleCheckboxChange()}
                />
            </div>

            <RefreshBtn onRefresh={onFilter}/>
        </div>

        {
            _.isEmpty(list) ? <div className="d-flex justify-content-center align-items-center">
                <img className="empty-img w-25" src={toAbsoluteUrl("/media/game/empty.svg")} alt=""/>
            </div> : list.map((draw, index) => {
                return <div className="fw-bolder fs-4" key={index}>
                    {/*<div className="fs-4 bg-gray-400 text-start ps-2 py-1">{draw.drawLabel}</div>*/}
                    {draw.tickets.map((ticket, i) => {
                        const ts = {
                            ...ticket,
                            channel: draw.tableName,
                            status: drawStatus.completed(draw.status),
                            drawLabel: draw.drawLabel,
                            drawCode: draw.drawCode
                        };
                        const results = draw?.results[0];
                        const resultValue = results?.result;

                        return <table key={i} className="bg-light w-100 border-bottom">
                            <tbody className="header-div-white">
                            <tr className="bg-gray-300">
                                <th className="text-start px-2 py-1"
                                    colSpan={7}>
                                    <div
                                        onClick={() => setTicket(ts)}
                                        className="fs-6">
                                        <span
                                            className="text-blue text-decoration-underline cursor-pointer">{ticket.ticketNumber}</span>
                                        <span className='ms-5'>
                                            {intl({id: "FIGHT_NO"})} #{draw.drawCode}
                                        </span>
                                        {resultValue && <span className="ms-5">{intl({id: "RESULT"})}: <span
                                            className={`${getColor(resultValue)}`}>{intl({id: resultValue})}</span></span>}
                                        <span className="ms-2">
                                             {<I18n t={"CHANNEL"}/>}: {draw.tableName}
                                        </span>
                                    </div>
                                </th>
                                <th className='px-2 py-1 text-end'>
                                    <Printer ticket={ts}/>
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    {ticket.items.map((item, j) => {
                                        return <div key={j} className="d-flex justify-content-start text-center pb-2">
                                            <div
                                                className={`w-25 ${getColor(item.betCode)}`}>{intl({id: `${item.betCode}`})}</div>
                                            <span>{moneyFormatter(item.betAmount)} </span>
                                            <div className="pe-1 ps-1">x</div>
                                            <div>{item.rebateValue.toFixed(2)}</div>
                                            <div className="pe-1 ps-1">=</div>
                                            <span className="text-danger">{
                                                drawStatus.completed(draw.status) ?
                                                    moneyFormatter(item.winAmount, currencyCode)
                                                    : "???"

                                            }</span>
                                        </div>
                                    })}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    })}
                </div>
            })
        }
        {ticketDl && <TicketModal onClose={() => setTicket(null)} ticket={ticketDl}/>}

    </div>
}
export default RecentBet;