import React, {useEffect} from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {toAbsoluteUrl} from "../../../helpers";
import Image from "react-bootstrap/Image";
import {useIntl} from "react-intl";
import {useAppSelector} from "../../../../app/redux/hooks/hook";
import {useBalance} from "../../../../app/modules/balance/action";
import {useGame} from "../../../../app/modules/game/core/action";
import {useAuth} from "../../../../app/modules/auth/core/action";
import {moneyFormatter} from "../../../helpers/components/MoneyUtils";
import {setAutoPrint} from "../../../../app/modules/game/core/reducer";
import BtnRefresh from "../../../../app/modules/game/components/dashboard/m99/BtnRefresh";
import {Button, Dropdown, Form} from "react-bootstrap";
import {I18n} from "../../../i18n/KiloITi18n";
import {M99Languages} from "../../../partials/layout/header-menus/M99Languages";
import {sendPrintToAndroid} from "../../../../app/modules/print/androidPrint";
import {getFullOrdinalDate} from "../../../helpers/dateUtils";

const M99Header = () => {
    const {currentUser, logout} = useAuth();
    const navigate = useNavigate();
    const intl = useIntl().formatMessage;
    const {balance} = useAppSelector(state => state.balance);
    const draw = useAppSelector(state => state.gameState.game.draw);
    const {fetchBalance} = useBalance();
    const param = useParams();
    const {fetchCurrentBet, handleCheckboxChange, isAutoPrint} = useGame();
    const tableId = draw.tableId ?? parseInt(param?.tableId ?? "")

    useEffect(() => {
        fetchBalance();
        fetchCurrentBet({tableId: tableId});
        // eslint-disable-next-line
    }, [])


    const location = useLocation()
    const isHome = location.pathname.length === 1;
    const backBtn = !isHome && <Link to="/" className="p-1 btn-back w-35px h-35px"/>

    const handleSwitchChange = () => {
        setAutoPrint(!isAutoPrint); // Toggle the isChecked state
        handleCheckboxChange();
    };

    const handleSetting = () => {
        sendPrintToAndroid("Setting");
    };

    return <nav
        id="m99"
        className="navbar navbar-light navbar-expand d-flex justify-content-between align-items-center px-1 py-1 m99-bg-brand">
        <div className="d-flex align-items-center">
            {backBtn}
            <Link className="navbar-brand navbar-m99" to="/" style={{padding: "0", marginRight: "5px"}}>
                <Image src={toAbsoluteUrl("/media/cockfighting/logo.png")} alt="Logo" loading="lazy"/>
            </Link>
            <div className="flex-column justify-content-center align-items-center">
                <div className="text-warning fw-bolder mt-2 m99-name fs-1">M99.ONLINE</div>
                <div className="text-warning m99-date fw-bold fs-3">{getFullOrdinalDate(new Date())}</div>
            </div>
        </div>
        <ul className="navbar-nav nav align-items-center d-grid d-lg-flex justify-content-center me-2">
            <div className="profile mx-2 d-flex justify-content-center align-items-center">
                <img src={toAbsoluteUrl("/media/cockfighting/logo.png")} className="custom-width mx-1" alt=""/>
                <div className="user-nav">
                    <h6 className="username mb-0">{currentUser?.username?.toUpperCase()}</h6>
                    <div className="d-flex justify-content-center align-middle">
                        <h6 className="account-balance text-white fw-bold balance">{moneyFormatter(balance.balance, balance.currencyCode)}</h6>
                        {/*<i className="las la-sync cursor-pointer display-6 fw-bolder text-white mx-1"/>*/}
                        <BtnRefresh onRefresh={() => fetchBalance()}/>
                    </div>
                </div>
            </div>
            <div className="d-flex right-menu">
                <Button variant="success" className="m-1 fw-bold text-nowrap"> {<I18n t={"PARLEY"}/>}</Button>
                <Dropdown>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic" className="m-1 fw-bold">
                        {<I18n t={"MENU"}/>}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => navigate(`/app-report/${tableId}`)}
                                       className="fw-bold py-2 fs-3 d-block d-md-none">
                            <i className="las la-play fs-3"/>{intl({id: "REPORT"})}
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => navigate(`/report/${tableId}`)}
                                       className="fw-bold py-2 fs-3 d-none d-md-block"><i
                            className="las la-play fs-2"/>{
                            <I18n
                                t={"TRANSITION_HISTORY"}/>}</Dropdown.Item>
                        <Dropdown.Item onClick={() => navigate("/change-pw")} className="fw-bold py-2 fs-3"><i
                            className="las la-play fs-3"/>{intl({id: "PASSWORD"})}</Dropdown.Item>

                        <Dropdown.Item className="fw-bold py-2 fs-3 d-md-none d-sm-block" onClick={handleSetting}>
                            <i className="las la-play fs-2"></i>
                            <span className="fs-4 fw-bold">{intl({id: "SETTING"})}</span>
                        </Dropdown.Item>

                        <Form.Check
                            type="switch"
                            id="print-switch"
                            label={<I18n t={"PRINT"}/>}
                            className="ms-4 py-2 fs-3 fw-bold d-flex justify-content-start align-items-center"
                            checked={isAutoPrint}
                            onChange={handleSwitchChange}
                        />
                    </Dropdown.Menu>
                </Dropdown>
                <Button variant="danger" className="m-1 text-nowrap radius-5px"
                        onClick={() => logout()}>{intl({id: "LOGOUT"})}</Button>
                <M99Languages/>
            </div>

        </ul>
    </nav>
}

export {
    M99Header
};