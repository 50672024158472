import {FC} from 'react'
import {useLang} from './KiloITi18n'
import {IntlProvider, ReactIntlErrorCode} from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/de'
import '@formatjs/intl-relativetimeformat/locale-data/es'
import '@formatjs/intl-relativetimeformat/locale-data/fr'
import '@formatjs/intl-relativetimeformat/locale-data/ja'
import '@formatjs/intl-relativetimeformat/locale-data/zh'
import '@formatjs/intl-relativetimeformat/locale-data/km'

import deMessages from './messages/de.json'
import enMessages from './messages/en.json'
import esMessages from './messages/es.json'
import frMessages from './messages/fr.json'
import jaMessages from './messages/ja.json'
import zhMessages from './messages/zh.json'
import thMessages from './messages/th.json'
import viMessages from './messages/vi.json'
import kmMessages from './messages/km.json'
import {WithChildren} from '../helpers'

const allMessages = {
    de: deMessages,
    en: enMessages,
    es: esMessages,
    fr: frMessages,
    ja: jaMessages,
    zh: zhMessages,
    th: thMessages,
    vi: viMessages,
    km: kmMessages,
}


function onError(e: any) {
    if (e.code === ReactIntlErrorCode.MISSING_DATA) {
        return;
    }
}
const I18nProvider: FC<WithChildren> = ({children}) => {
    const {selectedLang} = useLang()
    const messages = allMessages[selectedLang]

    return (
        <IntlProvider onError={onError} locale={selectedLang} messages={messages}>
            {children}
        </IntlProvider>
    )
}

export {I18nProvider}
