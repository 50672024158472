import React, {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_kiloit/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_kiloit/layout/core'
import {MasterInit} from '../_kiloit/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {initClient} from "./services/SocketService";
import {ToastContainer} from "react-toastify";

initClient();
const App = () => {
    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <I18nProvider>
                <LayoutProvider>
                    <AuthInit>
                        <Outlet/>
                        <MasterInit/>
                    </AuthInit>
                    <ToastContainer/>
                </LayoutProvider>
            </I18nProvider>
        </Suspense>
    )
}

export {App}
