import {useEffect} from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {toAbsoluteUrl} from "../../../helpers";
import Image from "react-bootstrap/Image";
import {Dropdown} from "react-bootstrap";
import {useIntl} from "react-intl";
import {useAppSelector} from "../../../../app/redux/hooks/hook";
import {moneyFormatter} from "../../../helpers/components/MoneyUtils";
import {useBalance} from "../../../../app/modules/balance/action";
import {useGame} from "../../../../app/modules/game/core/action";
import {isIframe} from "../../../helpers/utils";
import {getLocalSt, stKeys} from "../../../helpers/storage";
import {useAuth} from "../../../../app/modules/auth/core/action";
import {sendPrintToAndroid} from "../../../../app/modules/print/androidPrint";
import {LanguagesCustom} from "../../../partials/layout/header-menus/LanguagesCustom";
import {currencySign, platformType} from "../../../constants/general";
import RefreshBtn from "../../../partials/widgets/RefreshButton";
import ChannelWrapper from "../../../../app/modules/game/components/dashboard/ChannelWrapper";

const P99Header = () => {
    const {currentUser, logout} = useAuth();
    const navigate = useNavigate();

    const intl = useIntl().formatMessage;
    const {balance} = useAppSelector(state => state.balance);
    const draw = useAppSelector(state => state.gameState.game.draw);
    const {fetchBalance} = useBalance();
    const param = useParams();

    const {fetchCurrentBet} = useGame();
    const tableId = draw.tableId ?? parseInt(param?.tableId ?? "")

    useEffect(() => {
        fetchBalance();
        fetchCurrentBet({tableId: tableId});
        // eslint-disable-next-line
    }, [])

    const handleSetting = () => {
        sendPrintToAndroid("Setting");
    };
    const location = useLocation()
    const isHome = location.pathname.length === 1;
    const backBtn = !isHome && <Link to="/" className="p-1 btn-back w-35px h-35px"/>

    return <nav className="navbar navbar-expand-lg p99-header-bg p-0 h-60px position-fixed z-index-100 w-100">
        <div className="container-fluid">
            <div className="d-flex align-items-center" id="navbarSupportedContent">
                {(isIframe() && isHome) && <a href={getLocalSt(stKeys.redirectUrl)} className="btn-back">{""}</a>}
                {backBtn}
                <Link className="navbar-brand d-flex justify-content-center align-items-center pt-0" to="/">
                    <Image
                        className="w-50px"
                        src={toAbsoluteUrl(`/media/logo/${platformType}.png`)}
                        alt="MDB Logo"
                        loading="lazy"
                    />
                </Link>
                <div className="flex-column justify-content-center align-items-center">
                    <div className="text-light fw-bolder mt-2 fs-2 d-none d-lg-block">P99 Cock</div>
                </div>
            </div>

            <div className="d-flex justify-content-center align-items-center">
                <div className="d-none d-lg-block">
                    <ChannelWrapper/>
                </div>
                <div className="text-light fs-4 fw-bolder me-3 ms-6">{currentUser?.username}</div>

                <div
                    className="border border-light px-3 py-2 radius-100px d-flex align-items-center justify-content-center">
                    <div
                        className="fs-5 bg-yellow rounded-circle p-1 w-20px h-20px d-flex justify-content-center align-items-center fw-bold">
                        {currencySign[balance.currencyCode]}
                    </div>
                    <div className="ms-2 fs-4 text-yellow fw-bold">{moneyFormatter(balance.balance)}
                    </div>
                </div>

                <div className="mt-2">
                    <RefreshBtn onRefresh={() => fetchBalance()} className="text-warning fw-bold"/>
                </div>


                <Dropdown className="bg-transparent">
                    <Dropdown.Toggle variant="e" className="px-3 rounded-circle bg-transparent">
                        <i className="bi bi-three-dots-vertical fs-3 text-white"></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="bg-light w-250px">
                        <Dropdown.Item onClick={() => navigate(`/report/${tableId}`)}
                                       className="border-bottom text-dark fw-bold fs-4 py-4">
                            <i className="fa-solid fa-chart-column text-dark fs-2 me-4"/>
                            <span>{intl({id: "REPORT"})}</span>
                        </Dropdown.Item>

                        {!isIframe() && <>
                            <Dropdown.Item onClick={() => navigate("/change-pw")}
                                           className="border-bottom text-dark fw-bold fs-4 py-4">
                                <i className="fa-solid fa-lock text-primary fs-2 me-4"/>
                                <span>{intl({id: "PASSWORD"})}</span>
                            </Dropdown.Item>
                            <LanguagesCustom/>
                            <Dropdown.Item className="fw-bold py-4 d-block d-lg-none" onClick={handleSetting}>
                                <i className="bi bi-gear text-black fs-4 fw-bold me-4"></i>
                                <span className="fs-4 fw-bold">{intl({id: "SETTING"})}</span>
                            </Dropdown.Item>
                            <hr className="m-0 p-0"/>
                            <Dropdown.Item
                                onClick={() => logout()}
                                className="text-dark fw-bold fs-4 py-2">
                                <i className="fa-solid fa-right-from-bracket text-danger fs-2 me-4"/>
                                <span>{intl({id: "LOGOUT"})}</span>
                            </Dropdown.Item>
                        </>}
                    </Dropdown.Menu>
                </Dropdown>

            </div>
        </div>
    </nav>
}

export {P99Header};