import RecentBet from "./RecentBet";
import RecentReport from "./RecentReport";
import React, {useEffect, useState} from "react";
import {useGame} from "../../../core/action";
import {useAppSelector} from "../../../../../redux/hooks/hook";
import {TicketModal} from "../../../../print/p99/TicketModal";
import {FormattedMessage} from "react-intl";

const RecentBetWrapper = () => {
    const [active, setActive] = useState(0);
    const tableId = useAppSelector(state => state.gameState.game.tableId);
    const [ticketModal, setTicket] = useState<any>();

    const {fetchCurrentBetReport, fetchCurrentBet} = useGame();


    useEffect(() => {
        if (active > 0) {
            active > 1 ? fetchCurrentBetReport() : fetchCurrentBet({tableId: tableId});
        }
        // eslint-disable-next-line
    }, [active]);

    return <>
        <ul className="ff-hanunam nav nav-tabs nav-line-tabs nav-line-tabs-2x fs-4  text-white justify-content-center p99-bg-input">
            <li className="nav-item">
                <a
                    className="nav-link active py-3"
                    data-bs-toggle="tab"
                    href="#kt_tab_pane_1"
                    onClick={() => setActive(1)}
                >
                    <FormattedMessage id="CURRENT_BET"/>
                </a>
            </li>
            <li className="nav-item">
                <a
                    className="nav-link py-3"
                    data-bs-toggle="tab"
                    href="#kt_tab_pane_2"
                    onClick={() => setActive(2)}
                >
                    <FormattedMessage id="TODAY_REPORT"/>
                </a>
            </li>

        </ul>
        <div className="tab-content" id="myTabContent">
            <div
                className="tab-pane fade show active"
                id="kt_tab_pane_1"
                role="tabpanel">
                <RecentBet setTicket={setTicket}/>

            </div>
            <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
                <RecentReport setTicket={setTicket}/>
            </div>

        </div>
        {ticketModal && <TicketModal ticket={ticketModal} onClose={() => setTicket(null)}/>}

    </>
}
export default RecentBetWrapper;