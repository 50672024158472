export interface Balance {
    balance: number;
    bonus: number;
    outstanding?: Outstanding[];
    currencyCode: string;
}

export interface Outstanding {
    productCode: string;
    tableId: number;
    balance: number;
}

export const initBalance: Balance = {
    balance: 0,
    bonus: 0,
    currencyCode: ''
}
