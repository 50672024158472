import React from "react";
import {moneyFormatter} from "../../../../../../_kiloit/helpers/components/MoneyUtils";
import {getFullOrdinalDateWithTime} from "../../../../../../_kiloit/helpers/dateUtils";
import {I18n} from "../../../../../../_kiloit/i18n/KiloITi18n";

const M99PrintItems = (props: any) => {
    const {object, currency, status, isChinese, ticket} = props;
    const {betAmount, rebateValue, winAmount} = object;

    const RenderTR = ({label, item, subItem = ""}) => {
        return <div style={{lineHeight: "25px"}} key={label} className="d-flex m-0 align-items-center">
            <span style={{width: "30%"}}
                  className={isChinese ? "font-weight-none text-right f-bayon" : "font-weight-bolder text-right f-bayon f-22 p-0 m-0"}>{label}</span>
            <span className="f-bayon f-22">&nbsp;:&nbsp;</span>
            {
                subItem ?
                    <span className="text-center">
                        {moneyFormatter(item, currency, "text-black f-bayon f-22 font-weight-bolder")} <span
                        className="f-bayon f-22 font-weight-bolder">X {subItem}</span>
                    </span> :
                    <span className="f-bayon font-weight-bolder f-22 align-items-center text-center">
                        {item}
                    </span>
            }
        </div>
    };

    const RenderSteak = ({label, item}) => {
        return <div style={{lineHeight: "25px"}} key={label} className="d-flex m-0 align-items-center">
            <span style={{width: "30%"}}
                  className={isChinese ? "font-weight-none text-right f-bayon" : "font-weight-bolder text-right f-bayon f-22 p-0 m-0"}>{label}</span>
            <span className="f-bayon f-22">&nbsp;:&nbsp;</span>

            <span className="f-bayon font-weight-bolder f-22 align-items-center text-center">
                {moneyFormatter(item, currency)}
            </span>
        </div>
    };

    const RenderOdds = ({label, item}) => {
        return <div style={{lineHeight: "25px"}} key={label} className="d-flex m-0 align-items-center">
            <span style={{width: "30%"}}
                  className={isChinese ? "font-weight-none text-right f-bayon" : "font-weight-bolder text-right f-bayon f-22 p-0 m-0"}>{label}</span>
            <span className="f-bayon f-22">&nbsp;:&nbsp;</span>

            <span className="f-bayon font-weight-bolder f-22 align-items-center text-center">
                {item.toFixed(2)}
            </span>
        </div>
    };

    const RenderDate = (props: any) => {
        const {label, item} = props;
        return <div style={{lineHeight: "25px"}} className="d-flex align-items-center">
            <span style={{width: "30%"}}
                  className={isChinese ? "font-weight-none text-right f-bayon" : "font-weight-bolder text-right f-bayon f-22 p-0 m-0"}>{label}</span>
            <span className="f-bayon f-22">&nbsp;:&nbsp;</span>

            <span className="f-bayon font-weight-bolder f-22 align-items-center text-center">
                        {getFullOrdinalDateWithTime(item)}
                    </span>
        </div>
    }


    return <tr>
        <td className="p-0 text-dark font-weight-bolder">
            <RenderDate label="Date" item={ticket.createdAt}/>
            <RenderSteak label="Steak" item={betAmount}/>
            <RenderOdds label={<I18n t={"ODDS"}/>} item={rebateValue}/>
            <RenderTR label={<I18n t={"WIN.LOSE"}/>}
                      item={status ? moneyFormatter(winAmount, currency, "text-black f-bayon f-22 font-weight-bolder") : moneyFormatter(0, currency)}/>
        </td>

    </tr>
};

export default M99PrintItems;
