import {setBetPrize, setConfig, setLoading} from './reducer';
import {getBetPrize, getConfig} from "./_requests";
import {gameSettingModel} from "../../constants/config";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../redux/hooks/hook";

const useConfig = () => {
    const dispatch = useDispatch();
    const config = useAppSelector(state => state.config.gameConfig);

    const fetchConfig = (gameSetting: gameSettingModel) => {
        let config = getConfigLocal(gameSetting.configKey);
        if (config) {
            dispatch(setConfig(config));
            return;
        }
        dispatch(setLoading(true));
        getConfig({productCode: gameSetting.productCode}).then(res => {
            dispatch(setConfig(res));
            return res;
        })
    }
    const getConfigLocal = (type: string) => {
        let config = localStorage.getItem(type);
        if (config)
            return JSON.parse(config);
        return null;
    }

    const getBetType = () => {
        const slots = config.betTypes.filter(p => p.type === "SLOT");
        return {slots}
    }

    const fetchBetPrize = () => {
        getBetPrize().then(res => {
            dispatch(setBetPrize(res))
        });
    }

    return {fetchConfig, getConfig, getBetType, fetchBetPrize}

}
export {useConfig}



