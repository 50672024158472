import {useEffect, useState} from "react";
import {getFullDateTimePrint} from "../../helpers/dateUtils";

const DateTimer = () => {
    const [date, setDate] = useState(new Date());

    useEffect(() => {
        let inv = setInterval(() => {
            setDate(new Date())
        }, 1000)

        return () => {
            clearInterval(inv);
        }
    }, [])

    return <>{getFullDateTimePrint(date)}</>
}

export {DateTimer}