import GameTableWrapper from "../modules/game/components/game-table/GameTableWrapper";
import {platformType} from "../../_kiloit/constants/general";

type mModel = {
    id: number,
    icon: string,
    name: string,
    border: string,
    component: any
}

const stLabel = {
    RED: "R",
    BLUE: "W",
    CANCEL: "C",
    DRAW: "D",
}

const gameResults = {
    RED: "RED",
    BLUE: "BLUE",
    CANCEL: "CANCEL",
    DRAW: "DRAW",
}

const gameResultType = {
    SLOT: "SLOT"
}


const recentBetType = {
    current: "current",
    recent: "recent"
}

const recentBetTypeMapPlatform = {
    P99: recentBetType.current,
    M99: recentBetType.current,
    S157: recentBetType.recent
}

const getRecentBetType = recentBetTypeMapPlatform[`${platformType}`];

const stP99Label = {
    RED: "Meron",
    BLUE: "Wala",
    CANCEL: "Cancel",
    DRAW: "Draw",
}


let gameMenus: mModel[] = [
    {
        id: 0,
        icon: "socdia",
        name: "Xóc Đĩa",
        border: "border_cd",
        component: GameTableWrapper
    },
    {
        id: 1,
        icon: "taixiu",
        name: "Tai Xiu",
        border: "border_tx",
        component: GameTableWrapper
    }
]
export {gameMenus, stLabel, stP99Label, getRecentBetType, gameResults, gameResultType}