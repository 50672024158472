const stKeys = {
    isIframe: "isIframe",
    redirectUrl: "redirectUrl",
    currentBet: "currentBet",
    mute: "mute"
}

const getCSize = () => getLocalSt(stKeys.currentBet) ?? 3;

const setLocalSt = (key: string, value: string) => {
    localStorage.setItem(key, value);
}

const deleteLocalSt = (key: string) => {
    localStorage.removeItem(key);
}

const getLocalSt = (key: string, isParse = false) => {
    const st = localStorage.getItem(key);
    if (st) {
        if (isParse) return JSON.parse(st)
        return st;
    }
    return null
}

export {setLocalSt, getLocalSt, stKeys, deleteLocalSt, getCSize}