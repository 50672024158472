import {FC, useState} from "react";
import {toAbsoluteUrl} from "../../../../../../_kiloit/helpers";
import {chips} from "../../../../../constants/general";
import {useAppSelector} from "../../../../../redux/hooks/hook";
import {getColor, getStatus, getStatusLabel, getTranslateId, toK} from "../../../../../../_kiloit/helpers/utils";
import {BetPrice} from "../../../../config/_models";
import {BetItem, BetModel, DrawModel} from "../../../core/_models";
import {useGame} from "../../../core/action";
import {useIntl} from "react-intl";
import _ from "lodash";
import {stLabel} from "../../../../../constants/game";
import {getCurrencySymbol} from "../../../../../../_kiloit/helpers/components/MoneyUtils";
import {getLocalSt} from "../../../../../../_kiloit/helpers/storage";

type props = {
    draw?: DrawModel,
}
const BettingBoard: FC<props> = ({draw}) => {

    const intl = useIntl().formatMessage;
    const {betPrize} = useAppSelector(state => state.config);
    const bl = useAppSelector(state => state.balance.balance);
    const game = useAppSelector(state => state.gameState.game);
    const betCodes = game.statistic.betCodes;
    const drawCode = game.draw;
    const [activeChip, setChip] = useState<BetPrice>();
    const {postBetting} = useGame();
    const [betAmount, setBetAmount] = useState(0);
    const {getRateByCode} = useGame();
    const status = drawCode.currentDraw?.status;
    const savedDetail = getLocalSt('currentDrawDetail');

    const onBet = (betType: { betCode: string }) => {
        if (betAmount > 0) {
            const bItem: BetItem = {
                betCode: betType.betCode,
                betAmount: betAmount,
                currencyCode: bl.currencyCode,
                betLog: betAmount.toString(),
            }
            const bm: BetModel = {
                tableId: draw?.tableId || 3,
                items: [
                    bItem
                ]
            }

            postBetting(bm).then(() => {
                handleDelete();
            });
        }
        setBetAmount(0)
        // @ts-ignore
        setChip(0);
    }

    const onChangeChip = (chip: BetPrice) => {
        setChip(chip);
        const ba = betAmount + chip.value;
        setBetAmount(ba);
    }

    const handleDelete = () => {
        setBetAmount(0)
        // @ts-ignore
        setChip(0);

    }

    const BetPriceCard = ({item, index}) => {
        let chipGif = chips[index] ?? chips[4];
        if (activeChip?.value === item?.value)
            return <div onClick={() => onChangeChip(item)} className="btn-chip on" key={index}>
                <img src={toAbsoluteUrl(`/media/chips/up/${chipGif}.gif`)} alt=""/>
                <div className="sell-chip bg_chip10">
                    <span className="fs-3">{toK(item.label)}</span>
                </div>
            </div>
        return <div onClick={() => onChangeChip(item)} className="btn-chip" key={index}>
            <img src={toAbsoluteUrl(`/media/chips/${chipGif}.png`)} alt=""/>
            <div className="sell-chip bg_chip10">
                <span className="fs-3">{toK(item.label)}</span>
            </div>
        </div>
    }


    return <div className="col-12 col-md-12 col-lg-3 col-xl-3 fw-bolder bg-dark p-0 position-relative">
        <div className="p-2 p-lg-1">
            <div className="row my-2 d-flex">
                <div className={`col border-end d-flex justify-content-center align-items-center`}>
                    <span className={`fs-6 ${status === 0 ? "text-success" : "live text-danger"}`}>
                        {intl({id: getStatusLabel(status)})}
                    </span>
                </div>
                <div
                    className="col text-white text-center fs-6 border-end fs-lg-8 fs-xl-6">
                    <span className="text-nowrap">{intl({id: "FIGHT_NO"})}</span>
                    <div className="text-warning">#{drawCode?.currentDraw?.drawCode}</div>
                </div>
                <div className="col text-white fs-6 fs-lg-8 fs-xl-6 text-center">
                    {intl({id: "WINNER"})}
                    <div className={getColor(savedDetail)}>{intl({id: getTranslateId(savedDetail)})}</div>
                </div>
            </div>
            <div className="row">
                <div className="col text-center pe-lg-1">
                    <div className="border border-danger radius-top-right-20px">
                        <div
                            className="bg-danger text-white py-lg-2 py-xl-2 py-5 radius-top-right-20px fs-3 fs-lg-6">MERON
                        </div>
                        <div
                            className="fs-1  py-3 py-lg-1  py-xl-1 text-light">{getRateByCode("red").payout.toFixed(2)}</div>
                        <div onClick={status === 0 ? () => onBet({betCode: "RED"}) : undefined}
                             className={`btn btn-danger p-lg-1 w-100 radius-unset py-3 py-xl-2 ${status === 0 ? "" : "div-disabled"}`}>
                            <span><i className="fas fa-circle-plus fs-2 fs-lg-6 fs-xl-2"/></span>
                            <span className="text-light fs-3 fs-lg-6">{intl({id: getStatus(status)})}</span>
                        </div>
                    </div>
                </div>
                <div className="col text-center ps-lg-1">
                    <div className="border border-primary radius-top-left-20px">
                        <div
                            className="bg-primary text-white py-5 py-lg-2 py-xl-2  radius-top-left-20px fs-3 fs-lg-6">WALA
                        </div>
                        <div
                            className="fs-1  py-3 py-xl-1 py-lg-1 text-light">{getRateByCode("blue").payout.toFixed(2)}</div>
                        <div onClick={status === 0 ? () => onBet({betCode: "BLUE"}) : undefined}
                             className={`btn btn-primary p-lg-1 w-100 radius-unset py-3 py-xl-2 ${status === 0 ? "" : "div-disabled"}`}>
                            <span><i className="fas fa-circle-plus fs-2 fs-lg-6 fs-xl-2"/></span><span
                            className="text-light fs-3 fs-lg-6">{intl({id: getStatus(status)})}</span>
                        </div>
                    </div>
                </div>
            </div>

            {getRateByCode("draw").isEnable && <div className="row mt-2 mb-2">
                <div className="col">
                    <div onClick={status === 0 ? () => onBet({betCode: "DRAW"}) : undefined}
                         className={`btn btn-success p-lg-1 fs-lg-6 w-100 radius-unset py-3 py-xl-2 fw-bolder ${status === 0 ? "" : "div-disabled"}`}>
                        <span className="fs-3 fs-lg-6">DRAW</span>
                        <span className="mx-2"><i className="fas fa-circle-plus fs-2 fs-lg-6"/></span>
                        <span className="fs-lg-6 fs-3">{intl({id: getStatus(status)})}</span>
                        <span className="mx-2 fs-lg-6 fs-3">{getRateByCode("draw").payout.toFixed(2)}</span>
                    </div>
                </div>
            </div>}
            <div className="d-flex justify-content-between mt-2">
                <div className="border w-100 d-flex align-items-center">
                    <div
                        className="fs-4 text-warning px-2 border-end w-30px h-100 d-flex align-items-center justify-content-center">{getCurrencySymbol(bl.currencyCode)}
                    </div>
                    <input type="text" className="w-100 ms-2 fs-4"
                           value={betAmount || 0}
                           onChange={(e) => setBetAmount(Number(e.target.value))}/>
                </div>
                <div className="btn btn-danger radius-unset py-2 px-lg-1" onClick={handleDelete}>
                    {intl({id: "DELETE"})}
                </div>
            </div>
            <div className="row justify-content-center">
                {betPrize.map((item, index) => {
                    return <div key={index}
                                className="col-3 p-0 d-flex justify-content-center h-70px h-lg-50px h-xl-60px">
                        <BetPriceCard item={item} index={index} key={index}/>
                    </div>
                })}
            </div>
            <div className="d-flex bottom-0 mb-2 break-point">
                {betCodes.map((item, index) => {
                    return <div key={index} className="d-flex align-items-center me-2">
                        <div
                            className={`me-1 w-lg-20px h-lg-20px w-xl-30px h-xl-30px fs-lg-6 fs-xl-2 text-white d-flex justify-content-center align-items-center fs-2 st-bet-codes st-${_.toLower(item.betCode)}`}>
                            {stLabel[item.betCode]}
                        </div>
                        <span className="text-white fs-2 fs-xl-2 fs-lg-6">
                            {item.value}
                        </span>
                    </div>
                })}
            </div>
        </div>

    </div>
}
export default BettingBoard;