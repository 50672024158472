import {combineReducers} from '@reduxjs/toolkit'
import balanceSlice from "../modules/balance/reducer";
import configSlice from "../modules/config/reducer";
import gameReducer from "../modules/game/gameReducer";
import reportSlice from "../modules/report/core/reducer";
import authSlice from "../modules/auth/core/reducer";


const rootReducer = combineReducers({
    balance: balanceSlice,
    config: configSlice,
    gameState: gameReducer,
    report: reportSlice,
    auth: authSlice
})


export default rootReducer
