import ChannelWrapper from "../ChannelWrapper";
import BettingBoard from "./BettingBoard";
import React, {useState} from "react";
import {useAppSelector} from "../../../../../redux/hooks/hook";
import RecentBet from "./RecentBet";
import History from "./History";
import LiveVideoWrapper from "../../live/LiveVideoWrapper";


const M99DashBoard = () => {

    const {
        draw
    } = useAppSelector(state => state.gameState.game);
    const [size, setSize] = useState(3)


    return <div className="row m-0" id="m-99">
        <div className="col-12 col-lg-8 col-xl-8 col-md-12 col-sm-12 col-xs-12 col" style={{padding: "0"}}>
            <ChannelWrapper/>
            <LiveVideoWrapper draw={draw}/>
            <div className="d-none d-lg-block">
                <History/>
            </div>
        </div>
        <div className="col-12 col-lg-4 col-xl-4 d-lg-block d-xl-block col m99-bg-brand p-0">
            <BettingBoard draw={draw}/>
            <RecentBet size={size} setSize={setSize}/>
        </div>
        <div className="d-sm-block d-lg-none mt-5">
            <History/>
        </div>

    </div>
}

export default M99DashBoard;