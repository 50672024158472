import React, {FC} from "react";
import {DrawModel} from "../../core/_models";
import {LiveIframe} from "../../../../../_kiloit/layout/components/iframe/Iframe";
import ExternalLiveVideoWrapper from "./ExternalLiveVideoWrapper";

const LiveVideoWrapper: FC<{
    draw: DrawModel
}> = ({draw}) => {
    if (draw.liveUrl) return <ExternalLiveVideoWrapper src={draw.liveUrl}/>

    const search = `?token=${draw.liveServer?.accessToken}&url=${draw?.liveServer?.server?.protocol + "://" + draw?.liveServer?.server?.host}`

    return <div
        className="bg-black vid-border video-aspect-ratio d-flex align-items-center position-relative justify-content-center">
        {draw.liveServer && <LiveIframe pathname={search}/>}
    </div>
}
export default LiveVideoWrapper;