import React, {FC, useState} from "react";
import {toAbsoluteUrl} from "../../../../../../_kiloit/helpers";
import {useAppSelector} from "../../../../../redux/hooks/hook";
import {getColor, getStatus, getStatusLabel, getTranslateId} from "../../../../../../_kiloit/helpers/utils";
import {BetPrice} from "../../../../config/_models";
import {BetItem, BetModel, DrawModel} from "../../../core/_models";
import {useGame} from "../../../core/action";
import {useIntl} from "react-intl";
import {getLocalSt} from "../../../../../../_kiloit/helpers/storage";
import RecentBetWrapper from "./RecentBetWrapper";
import clsx from "clsx";
import {HistoryTable} from "./History";
import {NumericFormat} from 'react-number-format';

type props = {
    draw?: DrawModel,
}
const BettingBoard: FC<props> = ({draw}) => {

    const intl = useIntl().formatMessage;
    const {betPrize} = useAppSelector(state => state.config);
    const bl = useAppSelector(state => state.balance.balance);
    const game = useAppSelector(state => state.gameState.game);
    const drawCode = game.draw;
    const [activeChip, setChip] = useState<BetPrice>();
    const {postBetting} = useGame();
    const [betAmount, setBetAmount] = useState(0);
    const {getRateByCode} = useGame();
    const status = drawCode.currentDraw?.status;
    const savedDetail = getLocalSt('currentDrawDetail');

    const onBet = (betType: { betCode: string }) => {
        if (betAmount > 0) {
            const bItem: BetItem = {
                betCode: betType.betCode,
                betAmount: betAmount,
                currencyCode: bl.currencyCode,
                betLog: betAmount.toString(),
            }
            const bm: BetModel = {
                tableId: draw?.tableId || 3,
                items: [
                    bItem
                ]
            }

            postBetting(bm).then(() => {
                handleDelete();
            });
        }
        setBetAmount(0)
        // @ts-ignore
        setChip(0);
    }

    const onChangeChip = (chip: BetPrice) => {
        setChip(chip);
        const ba = betAmount + chip.value;
        setBetAmount(ba);
    }

    const handleDelete = () => {
        setBetAmount(0)
        // @ts-ignore
        setChip(0);

    }

    const BetPriceCard = ({item, index}) => {
        const isActive = activeChip === item;

        return <div onClick={() => onChangeChip(item)}
                    className="btn-chip h-70px h-lg-82px h-xl-82px w-70px w-lg-82px w-xl-82px"
                    key={index}>
            <div className="position-relative w-100 h-100">
                {isActive && <div
                    className="p99-coin-active  h-70px h-lg-78px h-xl-78px  w-70px w-lg-78px w-xl-78px top-10px"></div>}
                <img className={clsx("position-absolute z-index-2 p99-top-img", {
                    "top-1px": isActive
                })}
                     src={toAbsoluteUrl(`/media/coins/${item.value}.png`)}
                     alt=""/>

            </div>
        </div>
    }

    const labelStatus = getStatusLabel(status);


    return <div className="col-12 col-md-12 col-lg-4 col-xl-4 fw-bolder bg-black-dl px-0">
        <div className="w-100">
            <div className="d-flex justify-content-evenly bg-black text-white fs-4 fw-bold">
                <div className="col text-center py-2 mx-3">{intl({id: "FIGHT_NO"}).toUpperCase()}</div>
                <div className="col text-center py-2">{intl({id: "BET_STATUS"}).toUpperCase()}</div>
                <div
                    className="col text-center py-2 border-bottom border-yellow border-4">{intl({id: "WINNER"}).toUpperCase()}</div>
            </div>
            <div className="d-flex justify-content-evenly bg-dark-card pb-2 text-white fs-4 fw-bold">
                <div
                    className="col text-center bg-white bg-opacity-20 radius-bottom-left-5px radius-bottom-right-5px text-white fs-1">
                    {drawCode?.currentDraw?.drawCode}
                </div>
                <div
                    className="col text-center d-flex justify-content-center align-items-center bg-white bg-opacity-20 radius-bottom-left-5px radius-bottom-right-5px text-white fs-5 mx-3">
                    <span className={`px-2 fw-bold radius-50px p99-bg-${labelStatus}`}>
                        {intl({id: labelStatus})}
                    </span>
                </div>
                <div
                    className="col text-center align-items-center bg-white bg-opacity-20 radius-bottom-left-5px radius-bottom-right-5px text-white fs-1">
                    <div className={getColor(savedDetail)}>{intl({id: getTranslateId(savedDetail)})}</div>
                </div>

            </div>

            <div className="d-flex">
                <div className="col">
                    <img className="w-100" src={toAbsoluteUrl("/media/game/meron.png")} alt="__"/>
                    <div className="text-center fs-1 text-white fw-bold">
                        <strong>
                            ODD = {getRateByCode("red").payout.toFixed(2)}
                        </strong>
                    </div>
                    <div className="px-2">
                        <div onClick={status === 0 ? () => onBet({betCode: "RED"}) : undefined}
                             className={`btn btn-danger p-lg-2 w-100 radius-20px py-4 py-xl-3 ${status === 0 ? "" : "div-disabled"}`}>
                            <span><i className="fas fa-circle-plus fs-4 pb-2"/></span>
                            <span className="text-light fs-3">{intl({id: getStatus(status)})}</span>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <img className="w-100 p-0" src={toAbsoluteUrl("/media/game/wala.png")} alt="__"/>
                    <div className="text-center fs-1 text-white fw-bold">
                        <strong>
                            ODD = {getRateByCode("blue").payout.toFixed(2)}
                        </strong>
                    </div>
                    <div className="px-2">
                        <div onClick={status === 0 ? () => onBet({betCode: "BLUE"}) : undefined}
                             className={`btn btn-primary p-lg-2 w-100 radius-20px py-4 py-xl-3 ${status === 0 ? "" : "div-disabled"}`}>
                            <span><i className="fas fa-circle-plus fs-4 pb-2"/></span>
                            <span className="text-light fs-3">{intl({id: getStatus(status)})}</span>
                        </div>
                    </div>
                </div>
            </div>


            <div className="d-flex justify-content-between mt-6 px-2">
                <NumericFormat
                    onValueChange={(values) => {
                        setBetAmount(values.floatValue ?? 0)
                    }}
                    className="w-100 fs-4 border border-gray-500 p-3 px-6 text-white bg-black-dl radius-30px fw-bold form-control p99-bg-input"
                    value={betAmount} thousandSeparator=","/>
                <div
                    className="btn btn-danger p99-red-color radius-unset py-2  px-10 radius-30px d-flex justify-content-center align-items-center ms-1 "
                    onClick={handleDelete}>
                    <i className="bi bi-trash fs-2"></i>
                </div>
            </div>
            <div className="w-100 d-flex justify-content-center">
                <div className="p99-coin d-flex flex-wrap justify-content-center my-5 mw-400px">
                    {betPrize.map((item, index) => {
                        return <BetPriceCard item={item} index={index} key={index}/>
                    })}
                </div>
            </div>

            <RecentBetWrapper/>

        </div>
        <div className="d-block d-lg-none">
            <HistoryTable/>
        </div>
    </div>
}
export default BettingBoard;